import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SimpleButton(props) {
    const navigate = useNavigate();

    return (
        <div onClick={() => {
            props.navigate && navigate(props.navigate);
        }} className={`tw-px-[25px] tw-w-fit tw-border-[1px] tw-border-white tw-rounded-full tw-py-[5px] ${props.className} tw-cursor-pointer`}>
            <p>{props.text}</p>
        </div>
    )
}
