import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Hamburger from 'hamburger-react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

// assets
import enigmaLogo from '../../assets/icons/logos/enigmaLogo.svg';
import x from '../../assets/icons/x.svg';
import usa from '../../assets/icons/flags/usa.svg';
import uk from '../../assets/icons/flags/uk.svg';
import canada from '../../assets/icons/flags/canada.svg';
// import facebook from '../../assets/icons/socials/facebook.svg';
// import twitter from '../../assets/icons/socials/twitter.svg';
// import instagram from '../../assets/icons/socials/instagram.svg';
import linkedin from '../../assets/icons/socials/linkedin.svg';

export default function Navbar() {
    // initializations
    const navigate = useNavigate();

    // locals
    const [menuOpen, setMenuOpen] = useState(false);
    const [isTop, setIsTop] = useState(true);

    // globals
    const { screenWidth } = useSelector(state => state.responsive);

    // functions
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    // effects
    useEffect(() => {
        // Function to handle scroll event
        const onScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 50) {
                setIsTop(false);
            } else {
                setIsTop(true);
            }
        };

        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', onScroll);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('scroll', onScroll);
    }, []); // Empty array means this effect runs once on mount and once on unmount

    return (
        <div className={`${isTop ? 'tw-bg-transparent' : 'tw-bg-lightDark'} tw-transition-colors tw-duration-500 tw-border-b-[0.5px] tw-border-themeRed tw-sticky tw-top-[0vh] md:tw-top-[5vh] tw-z-[4]`}>
            <div className={`content_container tw-h-[60px] tw-text-white tw-flex tw-items-center tw-justify-between ${menuOpen ? 'tw-bg-lightDark' : ''}`}>
                <img src={enigmaLogo} className='md:tw-w-auto tw-w-[40vw] md:tw-h-auto tw-h-[8vw] tw-cursor-pointer' alt="logo" onClick={() => navigate('/')} />
                {screenWidth < 768 ?
                    <>
                        <Drawer
                            open={menuOpen}
                            onClose={toggleMenu}
                            direction='left'
                            style={{ width: '100vw', height: 'calc(100vh - 60px)', backgroundColor: '#121212', color: 'white', marginTop: '60px' }}
                        >
                            <div className='tw-flex tw-flex-col tw-justify-between tw-w-full tw-h-full tw-pb-[4vw]' onClick={toggleMenu}>
                                <div className='tw-flex tw-flex-col tw-w-full tw-items-start tw-px-[9vw] tw-py-[4vw] tw-gap-y-4'>
                                    {/* <div className='tw-flex tw-items-center tw-justify-between tw-w-full'>
                                        <img src={enigmaLogo} className='md:tw-w-[10.417vw] tw-w-[40vw] md:tw-h-[2.403vw] tw-h-[8vw] tw-cursor-pointer' alt="logo" onClick={() => navigate('/home')} />
                                        <img src={x} alt="x" className='tw-cursor-pointer' onClick={toggleMenu} />
                                    </div> */}

                                    <NavLink className='fz-16' to='/aboutUs'>About</NavLink>
                                    <NavLink className='fz-16' to='/enigmaKey'>Enigma Key</NavLink>
                                    <NavLink className='fz-16' to='/enigmaSsd'>Enigma SSD</NavLink>
                                    <NavLink className='fz-16' to='/enigmaQuantumSafe'>Enigma Network</NavLink>
                                    <NavLink className='fz-16' to='/frontierLink'>Enigma Frontier Link</NavLink>
                                    <NavLink className='fz-16' to='/contactUs'>Contact</NavLink>

                                    <div className='tw-w-full tw-h-[1px] tw-bg-themeRed'></div>

                                    <p><span className='tw-cursor-pointer fz-16' onClick={() => navigate('/auth/signup')}>Sign Up</span> / <span className='tw-cursor-pointer fz-16' onClick={() => navigate('/auth/login')}>Sign In</span></p>

                                    <div className='tw-flex tw-items-center tw-gap-x-2 fz-16'>
                                        <img src={usa} alt="usa" />
                                        <p>+1 929 777 3677</p>
                                    </div>
                                    <div className='tw-flex tw-items-center tw-gap-x-2 fz-16'>
                                        <img src={uk} alt="uk" />
                                        <p>+44 20 4570 1522</p>
                                    </div>
                                    <div className='tw-flex tw-items-center tw-gap-x-2 fz-16'>
                                        <img src={canada} alt="canada" />
                                        <p>+1 289 904 5262</p>
                                    </div>
                                </div>

                                <div className='tw-flex tw-flex-col tw-gap-y-2 tw-w-full'>
                                    <div className='tw-flex tw-items-center tw-justify-around tw-w-full'>
                                        {/* <img src={facebook} alt="social" className='md:tw-w-[1.389vw] tw-w-[7vw]' /> */}
                                        {/* <img src={instagram} alt="social" className='md:tw-w-[1.389vw] tw-w-[7vw]' /> */}
                                        {/* <img src={twitter} alt="social" className='md:tw-w-[1.389vw] tw-w-[7vw]' /> */}
                                        <img src={linkedin} alt="social" className='md:tw-w-[1.389vw] tw-w-[5vw]' />
                                    </div>
                                    <h1 className='fz-16 tw-text-[#808080] tw-text-center tw-mx-auto'>info@enigmakey.io</h1>
                                </div>
                            </div>
                        </Drawer>
                        <Hamburger color='#d11c1c' onToggle={toggleMenu} toggled={menuOpen} className='tw-fixed tw-z-10' />
                    </> :
                    <div className='tw-flex tw-items-center tw-gap-x-[1.736vw]'>
                        <NavLink className={`fz-18 tw-cursor-pointer`} to='/aboutUs'>About</NavLink>
                        <NavLink className={`fz-18 tw-cursor-pointer`} to='/enigmaKey'>Enigma Key</NavLink>
                        <NavLink className={`fz-18 tw-cursor-pointer`} to='/enigmaSsd'>Enigma SSD</NavLink>
                        <NavLink className={`fz-18 tw-cursor-pointer`} to='/enigmaQuantumSafe'>Enigma Network</NavLink>
                        <NavLink className={`fz-18 tw-cursor-pointer`} to='/frontierLink'>Enigma Frontier Link</NavLink>
                        <NavLink className={`fz-18 tw-cursor-pointer`} to='/contactUs'>Contact</NavLink>
                        {/* <div className='tw-h-[1.667vw] tw-w-[1px] tw-bg-white'></div> */}
                        {/* <h1>info@enigmakey.io</h1> */}
                    </div>
                }
            </div>
        </div>
    )
}
