import government from '../../assets/icons/about/government.svg';
import defence from '../../assets/icons/about/defence.svg';
import military from '../../assets/icons/about/military.svg';
import healthCare from '../../assets/icons/about/healthCare.svg';
import manufacturing from '../../assets/icons/about/manufacturing.svg';
import natoPartners from '../../assets/icons/about/natoPartners.svg';

export const weServe = [
    {
        id: 1,
        title: 'Government',
        icon: government,
    },
    {
        id: 2,
        title: 'Defence',
        icon: defence,
    },
    {
        id: 3,
        title: 'Military',
        icon: military,
    },
    {
        id: 4,
        title: 'Health Care',
        icon: healthCare,
    },
    {
        id: 5,
        title: 'Manufacturing',
        icon: manufacturing,
    },
    {
        id: 6,
        title: 'NATO Partners',
        icon: natoPartners,
    }
]