import React from 'react';

// components
import Hero from '../../components/hero/Hero';
import WhoWeAre from '../../components/whoWeAre/WhoWeAre';
import Features from '../../components/features/Features';
import RoadMap from '../../components/roadMap/RoadMap';
import KeyCapabilities from '../../components/keyCapabilities/KeyCapabilities';
import EnigmaApplication from '../../components/enigmaApplication/EnigmaApplication';
// import ContactUs from '../../components/contactUs/ContactUs';
import ContactUs from '../../components/contactUs/ContactUs';

export default function Home() {
    return (
        <div className='tw-bg-lightDark'>
            <Hero />
            <WhoWeAre />
            <Features />
            <RoadMap />
            <KeyCapabilities />
            <EnigmaApplication />
            
            <div className='tw-py-[25px]'>
                <ContactUs />
            </div>
        </div>
    )
}

