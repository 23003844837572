import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// assets
import enigmaFont from "../../../assets/bgs/hero/enigmaFont.svg";
// import vectorRight from "../../../assets/icons/vectorRight.svg";

export default function ProductsHero(props) {
	const { screenWidth } = useSelector(state => state.responsive);

	const [isMobile, setIsMobile] = React.useState(false);

	useEffect(() => {
		const updateIsMobile = () => {
			const newIsMobile = window.innerWidth < 768;
			if (newIsMobile !== isMobile) {
				setIsMobile(newIsMobile);
				// Reset the overflow style
				if (newIsMobile) {
					document.getElementById("root").style.overflow = "auto";
				}
			}
		};

		// Initial check
		updateIsMobile();

		// Add event listener for window resize
		window.addEventListener("resize", updateIsMobile);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateIsMobile);
		};
	}, [isMobile]); // <-- Dependency array should include isMobile here

	return (
		<div
			className="tw-min-h-[550px] tw-flex tw-items-center tw-pt-10"
			style={{
				backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.1)), ${props.changeHeroBg && isMobile
					? `url(${props.mobileHeroBg})`
					: `url(${props.bg})`
					}`,
				backgroundRepeat: "no-repeat",
				backgroundSize: `${props.right ? "cover" : screenWidth > 1700 ? "cover" : "cover"}`,
				backgroundPosition: "center",
			}}
		>
			<div className="content_container tw-w-full md:tw-grid tw-flex tw-flex-col tw-justify-center tw-gap-y-2 lg:tw-grid-cols-5 tw-grid-cols-1 tw-items-center">
				<div className="lg:tw-col-span-3 tw-col-span-1 tw-flex tw-flex-col lg:tw-items-start tw-items-center tw-gap-y-2 tw-relative">
					<div className="tw-flex md:tw-flex-row tw-flex-col tw-gap-x-2">
						<img
							className='md:tw-max-w-[400px] tw-max-w-[250px]'
							src={enigmaFont}
							alt="enigmaFont"
						/>

						{props.biggerTitle ? null : (
							<h1 className="tw-font-nasa fz-48 md:tw-text-start tw-text-center tw-text-white md:tw-relative md:tw-mt-8 tw-whitespace-nowrap">
								{props.title}
							</h1>
						)}
					</div>
					{props.biggerTitle ? (
						<h1 className="tw-font-nasa fz-36 lg:tw-text-start tw-text-center tw-text-white tw-whitespace-nowrap">
							{props.title}
						</h1>
					) : null}
					<h2 className="fz-20 tw-font-goodTime tw-text-white md:tw-text-left tw-text-center">
						{props.title2}
					</h2>
					<p className="fz-20 tw-text-white md:tw-block lg:tw-text-start tw-text-center">
						{props.description}
					</p>
				</div>
				<div className="lg:tw-col-span-2 tw-grid-cols-1 tw-flex tw-flex-col lg:tw-items-end tw-items-center tw-w-full">
					{props.right &&
						<img
							src={props.right}
							alt="enigmaFont"
							style={{
								width: `${screenWidth < 768 ? '250px' : 'auto'}`,
							}}
							className={`${props.changeHeroBg && "md:tw-block tw-hidden"}`}
						/>
					}
					{/* <div className="tw-flex tw-items-center tw-gap-x-2">
						{props.vectorLineRight ? (
							<img
								src={vectorRight}
								alt="vector"
								className="sm:tw-w-full tw-w-[65%]"
							/>
						) : null}
						<p className="tw-text-themeRed tw-font-semibold fz-20 tw-whitespace-nowrap">
							{props.model}
						</p>
					</div> */}
				</div>
			</div>
		</div>
	);
}
