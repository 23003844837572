import React from "react";
import { useSelector } from "react-redux";

// assets
import bg from "../../assets/icons/key/bg.png";
import right from "../../assets/icons/key/rightWithLine.svg";
import key from "../../assets/icons/key/right.svg";
import keyDimensions from "../../assets/icons/key/keyDimensions.svg";
import postQuantum from "../../assets/icons/key/postQuantum.png";
import modelDiff from "../../assets/bgs/key/modelDiff.svg";
import verticalRed from "../../assets/icons/key/verticalRed.svg";
import horizontalRed from "../../assets/icons/key/horizontalRed.png";
// import cloudInfrastructure from '../../assets/bgs/key/cloudInfrastructure.svg';
import security from "../../assets/bgs/key/security.svg";
import privateCloud from "../../assets/icons/key/cloudInfrastructure.svg";
import encTechBg from "../../assets/bgs/key/encTechBg2.png";

// components
import ProductsHero from "../../components/hero/productsHero/ProductsHero";
// import EncryptionTechnologies from "../../components/encryptionTechnologies/EncryptionTechnologies";
import TitleBullets from "../../components/titleBullets/TitleBullets";
import ContactUs from '../../components/contactUs/ContactUs';
import KeyDevelopmentRoadmap from '../../components/keyDevelopmentRoadMap/KeyDevelopmentRoadmap';
import RoundedKey from "../../components/roundedKey/RoundedKey";

export default function EnigmaKey() {
	const { screenWidth } = useSelector(state => state.responsive);
	return (
		<div className="tw-bg-lightDark">
			<ProductsHero
				title="Key"
				title2=""
				description="Military Grade Hardware Encryption and Authentication Technology using Post Quantum Cryptography (PQC), RSA, ECC & AES."
				model="Model 20232"
				vectorLineRight={true}
				bg={bg}
				right={right}
			/>

			<div className="tw-flex tw-flex-col content_container tw-gap-y-2 tw-text-white">
				<p className="fz-20 tw-font-thin tw-py-10 md:tw-text-left tw-text-justify">
					Enigma, including the EnigmaKey and EnigmaConsole Management Service,
					was developed by the founder of CyberClan. Enigma has developed a very
					unique proprietary hardware encryption platform along with a US
					provisional patent submission leveraging both artificial intelligence
					in the PKI distribution life cycle and blockchain to secure and manage
					the lifecycle of the product.
					<br /><br />
					Enigma will provide these solutions to clients in Defense, Government,
					Intelligence, Critical Infrastructure, Military, Healthcare,
					Manufacturing, and NATO partners, on a subscription model pricing.
					This model includes professional services with the deployment of the
					latest hardware encryption keys, fully managed in-house with our
					internal experts to ensure a successful customer experience.
				</p>
			</div>

			<div className="quantumCrypto tw-h-fit content_container "
				style={{
					backgroundImage: `${screenWidth < 1024 ? `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${postQuantum})` : `url(${postQuantum})`}`,
					// backgroundImage: `${`linear-gradient(to top, rgba(0, 0, 0, ${screenWidth < 1024 ? '0.7' : '0.4'}), rgba(0, 0, 0, 0.7)), url(${postQuantum})`}`,
					backgroundRepeat: "no-repeat",
					backgroundSize: `${screenWidth < 1024 ? "cover" : "contain"}`,
					// backgroundPosition: "left",
				}}
			>
				<div className="lg:tw-flex lg:tw-justify-end">
					<div className="tw-space-y-5 lg:tw-w-[75%] tw-py-10">
						<h2 className="fz-32 tw-text-white tw-font-goodTime md:tw-text-left tw-text-center">
							Post <span className="tw-text-themeRed">Quantum</span> Cryptography
						</h2>
						<p className="fz-20 tw-text-white tw-font-thin md:tw-text-left tw-text-justify">
							The integration of the Enigma Key with advanced cryptographic
							techniques like Post Quantum Cryptography (PQC), Quantum Key
							Distribution (QKD), RSA, ECC (Elliptic Curve Cryptography), and AES
							(Advanced Encryption Standard) represents a formidable approach to
							data security, blending classical and quantum encryption methods.
							<br /><br />
							PQC, designed to be secure against quantum computer attacks, ensures
							the Enigma Key's robustness in a future where traditional
							cryptographic methods might be vulnerable.
							<br /><br />
							QKD adds another layer of
							security, using the principles of quantum mechanics to distribute
							keys in a way that any attempt at eavesdropping can be detected,
							ensuring the integrity of the key exchange process.
							<br /><br />
							RSA, a
							widely-trusted algorithm, contributes to the Enigma Key's
							reliability in secure digital communications, particularly for key
							exchange and digital signatures.
							<br /><br />
							ECC complements this by providing
							similar levels of security to RSA but with smaller key sizes,
							enhancing efficiency and speed, which is particularly beneficial for
							devices with limited computational power.
							<br /><br />
							Finally, the incorporation
							of AES, a symmetric key algorithm renowned for its speed and
							security, guarantees fast and secure data encryption and decryption.
							This multi-faceted cryptographic approach makes the Enigma Key a
							highly secure and versatile tool in protecting sensitive information
							in an increasingly digital and interconnected world.
						</p>
					</div>
				</div>
			</div>

			<div
				className="tw-py-10"
				style={{
					backgroundImage: `url(${modelDiff})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<div className="content_container tw-grid md:tw-grid-cols-11 tw-grid-cols-1 tw-gap-5 tw-w-full md:tw-text-left tw-text-justify">
					<div className="tw-col-span-5 tw-flex tw-flex-col tw-items-center md:tw-space-y-5 tw-space-y-3">
						<h2 className="fz-28 tw-text-center tw-text-white tw-font-goodTime">
							<span className="tw-text-themeRed">Basic Security</span> Model
						</h2>

						<p className="fz-20 tw-text-white tw-text-justify tw-font-thin">
							The 20232 basic model offers the perfect balance of performance and
							security, with fast USB 3.0 speeds, and military-grade strength and
							durability. Available in capacities ranging from 32GB to 128GB, this
							model is designed to keep your data safe and secure, without
							compromising on speed. Its anodized aluminum enclosure and
							epoxy-filled casing meets the most stringent standards for
							protection, making it the ideal drive for your most important data.
						</p>
					</div>
					<div className="tw-col-span-1 tw-flex tw-justify-center tw-h-fit">
						<img src={verticalRed} alt="gap" className="md:tw-block tw-hidden" />
						<img src={horizontalRed} alt="gap" className="md:tw-hidden tw-block" />
					</div>
					<div className="tw-col-span-5 tw-flex tw-flex-col tw-items-center md:tw-space-y-5 tw-space-y-3">
						<h2 className="fz-28 tw-text-center tw-text-white tw-font-goodTime">
							<span className="tw-text-themeRed">Enterprise Security</span> Model
						</h2>

						<p className="fz-20 tw-text-white tw-text-justify tw-font-thin">
							The EnigmaKey 20232 Enterprise edition provides superior security
							and control with its powerful central administration of drive access
							and usage. The intuitive and secure online interface simplifies the
							process of managing thousands of EnigmaKey enterprise drives. With
							an activated license and the EnigmaConsole Management Service, you
							can remotely enforce password and access policies, let users recover
							lost passwords. This innovative product allows you to manage your
							drives from either cloud-based or on-premises servers.
						</p>
					</div>
				</div>
			</div>

			<div className="tw-bg-white tw-py-10">
				<div
					className="content_container tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-y-2  tw-place-items-center"
					style={
						{
							// backgroundImage: `url(${cloudInfrastructure})`,
							// backgroundRepeat: 'no-repeat',
							// backgroundSize: 'cover',
							// backgroundPosition: 'center',
						}
					}
				>
					<div className="tw-col-span-1 tw-flex tw-flex-col md:tw-items-start tw-items-center tw-gap-y-4">
						<h2 className="fz-32 md:tw-text-left tw-text-center tw-font-goodTime tw-underline tw-underline-offset-4 red-underline">
							Private Cloud Infrastructure
						</h2>
						<p className="fz-20">
							Enigma Key offers a cloud-neutral private infrastructure tailored
							for enterprises and government organizations, providing advanced
							security and encryption within clients' own data centers or through
							various IaaS providers like AWS, Microsoft, Google, IBM, and Oracle.
							Our scalable solutions are customizable to align with specific
							corporate standards and large-scale user bases, with pricing
							independent of IaaS costs.
						</p>
					</div>

					<div className="tw-col-span-1">
						<img
							src={privateCloud}
							alt="cloud"
							className="tw-mx-auto tw-bg-transparent"
						/>
					</div>
				</div>
			</div>

			{/* <EncryptionTechnologies
				technologies={[
					{
						id: 1,
						title: "Post Quantum Cryptography",
						short: "PQC",
					},
					{
						id: 2,
						title: "Rivest Shamir Adleman",
						short: "RSA",
					},
					{
						id: 3,
						title: "Elliptic Curve Cryptography",
						short: "ECC",
					},
					{
						id: 4,
						title: "Advanced Encryption Standard",
						short: "AES",
					},
				]}
			/> */}

			<KeyDevelopmentRoadmap
				phases={[
					{ id: 1, title: "The Genesis of Innovation", description: "Dawning of the Enigma Epoch: Crafting the Bedrock of Quantum Endeavors" },
					{ id: 2, title: "The Architectural Symphony", description: `Orchestrating Enigma's Infrastructure: Harmonizing the Core of Sales, Marketing, and Visionary Governance` },
					{ id: 3, title: "The Quantum Tapestry", description: `Weaving the Fabric of Tomorrow: Enigma's Quantum-Resilient Mosaic for the Digital Age` },
					{ id: 4, title: "The Renaissance of Technology", description: `Enigma's Technological Renaissance: Unveiling the Vanguard of Quantum-Ready Artefacts and Cognitive Mastery` }
				]}
			/>

			<RoundedKey centerPic={key} />

			<div
				className="tw-py-10"
				style={{
					backgroundImage: `url(${security})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<div className="content_container tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-justify-between">
					<div className="md:tw-w-[45%] tw-w-full tw-flex tw-flex-col tw-gap-y-4">
						<TitleBullets
							coloredTitle="Anti-Tamper Detection"
							title="Technology"
							bullets={[
								"Waterproof Casing",
								"Anti-Tamper Conductive Mesh",
								"Light Sensor Detection",
							]}
							block={true}
						/>
						<TitleBullets
							coloredTitle="Clone Prevention"
							title="Technology"
							bullets={[
								"PCB Potting",
								"Self-Destruction Mechanism",
								"Pulse Boost Module",
							]}
							block={true}
						/>
					</div>
					<div className="md:tw-w-[1px] tw-w-full md:tw-h-[20vw] tw-h-[1px] tw-bg-slate-300 tw-my-2"></div>
					<div className="tw-flex tw-flex-col tw-gap-y-2 md:tw-w-[45%] tw-w-full tw-items-end">
						<img
							src={keyDimensions}
							alt="key dimensions"
						/>
						{/* <img
							src={right2}
							alt="enigmaFont"
						/> */}
					</div>
				</div>
			</div>

			<div
				className={`content_container tw-py-10`}
				style={{
					backgroundImage: `${screenWidth < 768 ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${encTechBg})` : `url(${encTechBg})`}`,
					backgroundRepeat: "no-repeat",
					backgroundSize: `${screenWidth < 768 ? "cover" : "contain"}`,
					backgroundPosition: "left",
				}}
			>
				<div className="tw-flex tw-items-center tw-justify-end">
					<div className="tw-flex tw-flex-col tw-items-start tw-gap-y-2">
						<TitleBullets
							coloredTitle="Encryption"
							title="Technology"
							bullets={[
								"On-device crypto chip providing enhanced security",
								"RSA 4096 Asymmetric cryptography",
								"AES 128,192, 256-bit encryption",
								"Elliptic Curve",
							]}
						/>

						<TitleBullets
							coloredTitle="User Authentication"
							title="Technology"
							bullets={[
								"Complex password and passphrase security",
								"Mandatory multi-factor authentication",
							]}
						/>
					</div>
				</div>
			</div>

			<div className="tw-py-[25px]">
				<ContactUs />
			</div>
		</div>
	);
}
