import React from 'react';

// assets
import advancedEncryptionTech from '../../assets/icons/key/advancedEncryptionTech.svg';

export default function EncryptionTechnologies(props) {
    return (
        <div className='tw-py-10'

            style={{
                backgroundImage: `url(${props.aetBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundSize: 'contain',
            }}
        >
            <div className=' content_container tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-items-center  tw-relative '>
                <div className='tw-col-span-1 tw-py-10'>
                    <h1 className='fz-32 tw-font-goodTime tw-text-white tw-text-center'>Advanced Encryption <span className='tw-text-themeRed tw-block'>Technologies</span></h1>
                </div>
                <img src={advancedEncryptionTech} alt="advanced" className='md:tw-w-[52vw] 2xl:tw-w-[30vw] tw-absolute md:tw-h-[35vw] 2xl:tw-h-[25vw] sm:tw-h-[40vw] tw-h-[70vw] min-[1440px]:tw-left-[10%] md:tw-left-[15%] sm:tw-left-40 tw-left-5 md:tw-top-auto tw-top-[30%] tw-py-10' />
                <div className='tw-col-span-1 tw-flex tw-flex-col lg:tw-gap-y-16 sm:tw-gap-y-8 tw-gap-y-5 md:tw-ps-0 sm:tw-ps-20 tw-ps-0'
                >
                    {props.technologies.map(technology =>
                        <div key={technology.id} className={`tw-p-[1.5vw] tw-bg-[#989898] tw-w-fit tw-rounded-lg tw-relative ${(technology.id === 1 || technology.id === 4) ? 'md:tw-right-[1vw] md:tw-left-auto tw-left-[25vw]' : 'md:tw-left-[4vw] tw-left-[30vw]'}`}>
                            <p className='md:tw-text-[20px] sm:tw-text-[14px] tw-text-[12px] tw-text-white'>{technology.title} <span className='tw-text-themeRed'>({technology.short})</span></p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
