import React, { useEffect, useState } from 'react';
import axios from 'axios';

// MUI
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon sx={{ color: 'white' }} /> : <KeyboardArrowDownIcon sx={{ color: 'white' }} />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ color: 'white' }}>
                    {row.name}
                </TableCell>
                {/* <TableCell align="right">{row.identifiedAs}</TableCell> */}
                {/* <TableCell align="right">{row.companyName}</TableCell> */}
                <TableCell align="right" sx={{ color: 'white' }}>{row.email}</TableCell>
                {/* <TableCell align="right">{row.phoneCountryCode}{row.phoneNumber}</TableCell> */}
                {/* <TableCell align="right">{row.whatsappCountryCode}{row.whatsappNumber}</TableCell> */}
                <TableCell align="right" sx={{ color: 'white' }}>{row.contact}</TableCell>
                <TableCell align="right" sx={{ color: 'white' }}>{row.option}</TableCell>
                {/* <TableCell align="right">{row.projectBudget}</TableCell> */}
                {/* <TableCell align="right">{row.subscribedToNewsLetter ? "Yes" : "No"}</TableCell> */}
                <TableCell align="right" sx={{ color: 'white' }}>{new Date(row.at).toLocaleDateString()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ color: 'white' }}>
                                Message
                            </Typography>
                            <h1 className='tw-text-white'>{row.message}</h1>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function Queries() {
    const [queries, setQueries] = useState([]);

    useEffect(() => {
        axios.get('https://enigmakey.io/server/contactUs')
            .then(res => {
                setQueries(res.data);
            })
            .catch(err => {
                alert('Failed to fetch queries');
                console.log(err);
            })
    }, [])

    return (
        <div>
            <TableContainer className='tw-px-[9.115vw] tw-pt-[8vw] tw-pb-[4vw]' component={Paper} sx={{
                backgroundColor: '#121212'
            }}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell sx={{ color: 'white' }}>Name</TableCell>
                            {/* <TableCell align="right">Identified As</TableCell> */}
                            {/* <TableCell align="right">Company Name</TableCell> */}
                            <TableCell align="right" sx={{ color: 'white' }}>Email</TableCell>
                            <TableCell align="right" sx={{ color: 'white' }}>Contact</TableCell>
                            <TableCell align="right" sx={{ color: 'white' }}>Option</TableCell>
                            {/* <TableCell align="right" sx={{ color: 'white' }}>Message</TableCell> */}
                            {/* <TableCell align="right">Whatsapp</TableCell> */}
                            {/* <TableCell align="right">Budget</TableCell> */}
                            {/* <TableCell align="right">Subscribed</TableCell> */}
                            <TableCell align="right" sx={{ color: 'white' }}>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queries.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
