import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

// assets
import { swiperData } from '../../../data/auth/swiperData';
import enigmaLogo from '../../../assets/icons/logos/enigmaLogo.svg';

// components
import AuthSwiper from '../../../components/authSwiper/AuthSwiper';

export default function Signup() {
    // initializations
    const navigate = useNavigate();

    // locals
    const options = window.intlTelInputGlobals.getCountryData().map(code => {
        return {
            value: `+${code.dialCode}`,
            label: (
                <div className="tw-flex tw-items-center tw-gap-x-2 fz-20">
                    <img src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code.iso2.toUpperCase()}.svg`} alt="" style={{ width: '20px', height: '20px' }} />
                    <p style={{ fontSize: '20px !important' }} className='fz-20'>+{code.dialCode}</p>
                </div>
            )
        }
    });

    return (
        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-1 tw-place-items-center tw-text-white md:tw-h-[100vh] tw-relative tw-overflow-y-hidden'>

            <img src={enigmaLogo} alt="logo" className='tw-absolute tw-top-[2%] tw-left-[5%] tw-cursor-pointer' onClick={() => navigate('/')} />

            <div className='md:tw-col-span-4 tw-col-span-1 tw-bg-lightDark md:tw-px-[20vw] tw-px-[9vw] tw-flex tw-flex-col tw-items-center tw-gap-y-[0.6vw] tw-w-full tw-h-[100vh] tw-justify-center tw-overflow-y-scroll hide-scrollbar tw-pt-[5vw]'>
                {/* <img src={enigmaLogo} alt="logo" className='tw-self-start md:tw-hidden tw-block' /> */}

                <h1 className='tw-font-goodTime'>Sign Up</h1>

                <div className="tw-flex tw-flex-col tw-gap-y-1 tw-w-full">
                    <label htmlFor="name" className='tw-text-base'>Name</label>
                    <input type="text" placeholder='Name' className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-slate-300 tw-border-[1px]' />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-y-1 tw-w-full">
                    <label htmlFor="email" className='tw-text-base'>Email</label>
                    <input type="text" placeholder='Email' className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-slate-300 tw-border-[1px]' />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-y-1 tw-w-full">
                    <label htmlFor="email" className='tw-text-base'>Phone Number</label>
                    <div className='tw-flex tw-items-center'>
                        <Select options={options}
                            placeholder="Country"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    borderRight: '1px !important',
                                    borderColor: '#bdc6d1 !important',
                                    outline: '0px !important',
                                    paddingTop: '6px !important',
                                    paddingBottom: '6px !important',
                                    backgroundColor: 'black !important',
                                    borderTopRightRadius: '0px !important',
                                    borderBottomRightRadius: '0px !important',
                                    color: 'white !important',
                                }),
                                menu: (base) => ({
                                    ...base,
                                    backgroundColor: 'black !important',
                                    color: 'white !important',
                                    fontSize: '20px !important'
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused ? 'grey' : provided.backgroundColor,
                                    color: state.isFocused ? 'white' : provided.color,
                                    ':active': {
                                        ...provided[':active'],
                                        backgroundColor: !state.isDisabled && (state.isSelected ? 'data' : 'grey'),
                                    },
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    color: 'white',
                                    fontSize: '20px !important'
                                }),
                            }}
                            className='tw-w-[65%]'
                        />
                        <input name='phoneNumber' type="number" className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-r-md tw-text-white tw-w-full tw-border-slate-300 tw-border-r-[1px] tw-border-y-[1px] tw-border-l-0' placeholder='Contact' />
                    </div>
                </div>

                <div className="tw-flex tw-flex-col tw-gap-y-1 tw-w-full">
                    <label htmlFor="password" className='tw-text-base'>Password</label>
                    <input type="password" placeholder='password' className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-slate-300 tw-border-[1px]' />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-y-1 tw-w-full">
                    <label htmlFor="confirmPassword" className='tw-text-base'>Confirm Password</label>
                    <input type="password" placeholder='Confirm Password' className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-slate-300 tw-border-[1px]' />
                </div>

                <div className='tw-flex tw-items-center tw-w-full tw-justify-between'>
                    <div className='tw-flex tw-items-center tw-gap-x-[5px]'>
                        <input type="checkbox" className='tw-bg-themeRed' />
                        <p className=''>I agree with the</p>
                        <Link className='tw-text-sm tw-text-blue-500'>Terms of Service</Link>
                    </div>
                </div>

                <button className='tw-bg-themeRed tw-rounded-lg tw-py-[15px] tw-px-[77px] tw-w-full'>Next</button>

                <div className='tw-flex tw-items-center tw-justify-center tw-gap-x-[5px] tw-text-sm'>
                    <p>Already have an account?</p>
                    <Link to={'/auth/login'} className='tw-text-blue-500'>Sign In</Link>
                </div>
            </div>
            <div className='tw-col-span-2 tw-w-full md:tw-h-full tw-h-[100vh]'>
                <AuthSwiper data={swiperData} />
            </div>
        </div>
    )
}
