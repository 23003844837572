import React, { useEffect } from "react";
import bg from "../../assets/icons/quantumSafe/bg.png";
import right from "../../assets/icons/quantumSafe/rightPic.png";
import ProductsHero from "../../components/hero/productsHero/ProductsHero";
import enigmaNetwork from "../../assets/icons/quantumSafe/enigmaNetwork.png";

import blockchainTechImg from "../../assets/icons/quantumSafe/thirdSection.png";
import fourthSectionImg from "../../assets/icons/quantumSafe/fourthSection.png";
import fifthSectionImg from "../../assets/icons/quantumSafe/fifthSection.png";

import sixthSectionImg from "../../assets/icons/quantumSafe/lastSection.png";
import ContactUs from '../../components/contactUs/ContactUs';
import mobileHeroBg from "../../assets/icons/quantumSafe/mobileHeroBg.png";
import { useSelector } from "react-redux";

function EngimaQuantumSafe() {
	const { screenWidth } = useSelector(state => state.responsive);
	return (
		<div className="tw-bg-lightDark">
			<ProductsHero
				title="Quantum Safe AI Network"
				title2="Revolutionizing Secure Communication"
				biggerTitle={true}
				description=""
				vectorLineRight={false}
				model=""
				bg={bg}
				// right={right}
				changeHeroBg={true}
				mobileHeroBg={mobileHeroBg}
			/>
			<div className="tw-flex tw-flex-col tw-gap-y-2 content_container">
				<h1 className="tw-font-goodTime tw-text-themeRed tw-text-center fz-32 tw-mb-4">
					The Future of Digital Communication for Governments and Enterprises
				</h1>
				<p className="tw-text-white tw-text-center tw-font-thin fz-20">
					In a world where digital communication is at the heart of every
					operation, the Enigma AI Network emerges as a pioneering solution,
					offering state-of-the-art security and efficiency. Designed
					specifically for government agencies and large-scale enterprises, this
					platform redefines the standards of secure and intelligent
					communication.
				</p>
			</div>
			<div className="content_container ">
				<div className="md:tw-grid tw-grid-cols-2 tw-gap-10 tw-items-center tw-flex tw-flex-col-reverse tw-py-20">
					<div className="detailsCol tw-space-y-5 tw-h-fit">
						<h1 className="tw-font-goodTime tw-text-white fz-32 md:tw-w-[80%] tw-w-full md:tw-text-start tw-text-center">
							<span className="tw-text-themeRed">Engage with Enigma</span> Quantum
							Secured AI Network <span className="tw-text-themeRed">Today</span>
						</h1>
						<p className="tw-text-white tw-text-justify tw-font-thin fz-20">
							Experience the future of secure and intelligent communication with
							the Enigma AI Network. Our platform is not just a tool; it's a step
							into a new era of digital interaction, where security and efficiency
							go hand in hand. <br />
							<br />
							Contact us now to schedule a demonstration and see firsthand how the
							Enigma AI Network can meet your communication and security needs.
							<br />
							<br />
							Let's embark on this journey of secure, efficient, and intelligent
							communication together. The Enigma AI Network - where cutting-edge
							technology meets unparalleled security.
						</p>
					</div>
					<div className="tw-flex md:tw-justify-end  tw-justify-center">
						<img src={enigmaNetwork} alt="mobile pic" />
					</div>
				</div>
			</div>

			{/* Third Section */}
			<div
				className="lg:tw-min-h-[595px] tw-h-fit tw-py-10 md:tw-grid lg:tw-grid-cols-3 tw-items-center tw-bg-[#121212] content_container"
				style={{
					backgroundImage: `url(${blockchainTechImg})`,
					backgroundSize: `${screenWidth > 768 ? "contain" : "cover"}`,
					backgroundRepeat: "no-repeat",
					height: "100%",
				}}
			>
				<div className="lg:tw-block tw-hidden"></div>
				<div className="detailsDiv md:tw-col-span-2">
					<div className="lg:tw-pr-0 tw-px-5">
						<h1 className="tw-font-goodTime tw-text-white fz-32">
							<span className="tw-text-themeRed">Blockchain</span> Technology
						</h1>
						<p className="tw-text-white tw-text-justify tw-font-thin fz-20">
							Groups allows users to form communities within our network,
							providing a flexible space for collaboration and discussion.
							Enhanced with moderation tools, group administrators can manage
							their communities effectively.
						</p>
					</div>
					<div className="tw-mt-10 lg:tw-pr-0 tw-px-5">
						<h1 className="tw-font-goodTime tw-text-white fz-32">
							<span className="tw-text-themeRed">end-to-end </span> encrypted
						</h1>
						<p className="tw-text-white tw-text-justify tw-font-thin fz-20">
							Enigma Secured Network provides users with the ability to send
							instant messages in a secure environment. With end-to-end
							encryption, conversations remain private. The intuitive interface
							allows for seamless communication, making it an ideal platform for
							both personal and professional interactions.
						</p>
					</div>
				</div>
			</div>

			{/* Forth Section */}
			<div
				className=""
				style={{
					backgroundImage: `url(${fourthSectionImg})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					height: "100%",
				}}
			>
				<div className="content_container tw-py-20 md:tw-grid tw-grid-cols-9 tw-flex tw-flex-col tw-justify-center tw-gap-10">
					<div className="leftTextDiv tw-col-span-4">
						<h1 className="tw-font-goodTime tw-text-white fz-24 tw-text-center">
							<span className="tw-text-themeRed">Quantum Encrypted Calls</span>{" "}
							<br />
							Secure Communications
						</h1>
						<p className="tw-text-white tw-text-justify tw-font-thin tw-mt-5 fz-20">
							Experience secure and private voice calls with our quantum
							encryption technology. We offer an impenetrable defense against
							eavesdropping, ensuring your conversations, from individual to
							conference calls, remain confidential without
							sacrificing sound quality.
						</p>
					</div>
					<div className="tw-flex tw-justify-center tw-h-full tw-col-span-1">
						<div className="md:tw-w-[1px] md:tw-h-[50%] tw-bg-white tw-m-auto tw-w-[50%] tw-h-[1px]"></div>
					</div>
					<div className="rightTextDiv tw-col-span-4">
						<h1 className="tw-font-goodTime tw-text-white fz-24 tw-text-center">
							<span className="tw-text-themeRed">Unparalleled</span> Security{""}
							<br />
							<span className="tw-text-themeRed">at Your</span> Fingertips
						</h1>
						<p className="tw-text-white tw-text-justify tw-font-thin tw-mt-5 fz-20">
							At the Enigma AI Network, we understand the importance of
							confidentiality and integrity in your communications. Our platform
							is equipped with advanced encryption technologies, including
							post-quantum encryption, AES, ECC, and RSA, to ensure your chats,
							voice calls, video calls, and document sharing are protected against
							any breach.
						</p>
					</div>
				</div>
			</div>

			{/* Fifth Section */}
			<div className="tw-py-20" style={{
				backgroundImage: `url(${fifthSectionImg})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100%",
				width: "100vw",
			}}>
				<div
					className="content_container tw-space-y-10"

				>
					<div className="firstRow md:tw-grid tw-grid-cols-2 tw-gap-20">
						<div className="">
							<h1 className="tw-text-white fz-32 tw-uppercase">
								Content Moderation:
							</h1>
							<p className="tw-mt-2 tw-text-white tw-text-justify tw-font-thin fz-20">
								We ensure that all conversations adhere to the highest standards.
								Our AI monitors interactions to maintain a professional and safe
								communication environment, aligning with community standards and
								guidelines.
							</p>
						</div>
						<div className="md:tw-block tw-hidden"></div>
					</div>
					<div className="secondRow md:tw-grid tw-grid-cols-2 tw-gap-20">
						<div className="md:tw-block tw-hidden"></div>
						<div className="">
							<h1 className="tw-text-white fz-32 tw-uppercase">
								Personalized Experiences:
							</h1>
							<p className="tw-mt-2 tw-text-white tw-text-justify tw-font-thin fz-20">
								The AI at the core of Enigma learns and adapts to your
								preferences, offering a customized chat experience tailored to
								each user and organization.
							</p>
						</div>
					</div>
					<div className="thirdRow md:tw-grid tw-grid-cols-2 tw-gap-20">
						<div className="">
							<h1 className="tw-text-white fz-32 tw-uppercase">
								Language Translation:
							</h1>
							<p className="tw-mt-2 tw-text-white tw-text-justify tw-font-thin fz-20">
								Bridge language barriers with real-time AI-powered translation,
								facilitating seamless communication between diverse language
								speakers.
							</p>
						</div>
						<div className="md:tw-block tw-hidden"></div>
					</div>
					<div className="fourthRow md:tw-grid tw-grid-cols-2 tw-gap-20">
						<div className="md:tw-block tw-hidden"></div>
						<div className="">
							<h1 className="tw-text-white fz-32 tw-uppercase">
								Spam and Malware Detection:
							</h1>
							<p className="tw-mt-2 tw-text-white tw-text-justify tw-font-thin fz-20">
								Our AI's advanced pattern analysis identifies and filters out spam
								or malicious content, ensuring clean and safe communication
								channels.
							</p>
						</div>
					</div>
				</div>
			</div>


			{/* Sixth Section */}
			<div
				style={{
					backgroundImage: `url(${sixthSectionImg})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center center",
					width: "100vw",
				}}
				className="md:tw-h-[750px] sm:tw-h-[450] tw-h-[250px]"
			></div>

			{/* last section */}
			<div className="content_container">
				<p className="tw-text-white tw-text-justify tw-font-thin tw-py-20 fz-20">
					Automated Encryption Management: Our intelligent AI not only secures
					your communication but also smartly manages and recommends the best
					encryption settings. It adapts to user behavior and perceived threat
					levels, providing you with peace of mind. <br />
					<br />
					Anomaly Detection: Stay ahead of security breaches. Our AI is
					constantly vigilant, detecting unusual patterns or behaviors in
					communication that could signal hacking attempts or other security
					threats.
				</p>
			</div>
			
			<div className="tw-py-[25px]">
				<ContactUs />
			</div>
		</div>
	);
}

export default EngimaQuantumSafe;
