import React from 'react';

// assets
import secureLaptop from '../../assets/whoWeAre/secureLaptop.svg';

export default function WhoWeAre() {
    return (
        <div className='tw-pb-[75px]'>
            <div className='content_container tw-w-full tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-items-center'>
                <div className='md:tw-col-span-2 tw-col-span-1 tw-flex tw-flex-col md:tw-items-start tw-items-center tw-gap-y-4 tw-tracking-wide md:tw-order-1 tw-order-last'>
                    <h1 className='tw-font-goodTime tw-text-themeRed fz-54'>Who We Are?</h1>
                    <p className='tw-text-white fz-20 tw-font-thin md:tw-text-left tw-text-justify'>
                        Introducing Enigma Key, born from the visionary mind behind CyberClan. Our revolutionary product, Enigma Key, is crafting an unparalleled proprietary hardware encryption platform. With a US provisional patent submission, we're innovatively integrating artificial intelligence into the PKI distribution life cycle and blockchain to fortify and streamline the entire product life cycle. <br /><br />
                        Tailored for clients in Defense, Government, Intelligence, Critical Infrastructure, Military, Healthcare, Manufacturing, and NATO partners, Enigma Key offers subscription-based solutions. Our pricing structure includes mission-oriented hardware and software, a securely managed network, a secure mobile application, a secure smartwatch, and professional services. This guarantees a smooth deployment of the most advanced post-quantum encryption algorithm and key exchange. These keys are expertly managed in-house by our internal specialists, guaranteeing a smooth and successful customer experience. <br /><br /> Headquartered in the United Kingdom, Enigma Key has a global presence with offices strategically located around the world. Join us in unlocking the future of secure and intelligent encryption solutions.
                    </p>

                </div>
                <div className='tw-w-full tw-col-span-1 md:tw-bg-radialRed md:tw-order-last tw-order-1'>
                    <img src={secureLaptop} alt="secure" className='md:tw-w-[35vw] tw-w-full' />
                </div>
            </div>
        </div>
    )
}
