import React from 'react';
import { useSelector } from 'react-redux';

// assets
import { features } from '../../data/home/features';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default function Features() {
    // globals
    // const { inDarkMode } = useSelector(state => state.uiSlice);
    const { screenWidth } = useSelector(state => state.responsive);

    return (
        <div className='tw-pb-[75px]'>
            <div className='content_container tw-w-full'>
                <h1 className='tw-font-goodTime tw-text-themeRed fz-48 tw-text-center'>Features</h1>
                <p className='dark:tw-text-white tw-text-black fz-20 md:tw-text-center tw-text-justify tw-font-thin'>
                    Share your most sensitive information securely and confidently with the latest and most advanced security technology. Our military-grade encryption, artificial intelligence, and private blockchain technology ensure your data is <span className='tw-text-themeRed'>safe and secure.</span>
                </p>
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={screenWidth < 768 ? 2 : 4}
                    spaceBetween={50}
                    modules={[Pagination, Autoplay]}
                    className='featuresSwiper'
                // style={{ height: screenWidth >= 768 && '15vw' }}
                >
                    {features.map(feature =>
                        <SwiperSlide key={feature.id}>
                            <div className='tw-flex tw-flex-col tw-items-center md:tw-my-[1.5vw] tw-my-[5vw] tw-h-[150px]'>
                                <img className='tw-bg-radialRed tw-max-w-[60px]' src={feature.src} alt="feature" />
                                <p className='dark:tw-text-white tw-text-black fz-12 tw-text-center tw-w-full tw-mt-3 tw-px-5 tw-font-thin'>{feature.title}</p>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>

    )
}
