import React from "react";
import heroBg from "../../assets/icons/quantumSafe/bg.svg";
import heroRightSectionImage from "../../assets/icons/frontierLink/heroImage.png";
import unbreakableDataImg from "../../assets/icons/frontierLink/secondSection.png";
import digitalCommunication from "../../assets/icons/frontierLink/thirdSection.png";
import earthBg from "../../assets/icons/frontierLink/earthBg.png";
import ProductsHero from "../../components/hero/productsHero/ProductsHero";
import ContactUs from '../../components/contactUs/ContactUs';

function FrontierLink() {
	return (
		<div className="tw-bg-lightDark">
			<ProductsHero
				title="Frontier Link"
				title2="Revolutionize Your Communication with Enigma"
				biggerTitle={false}
				description="Introducing Enigma's Military-Grade, Quantum-Safe Communication Solution, The Ultimate Quantum Safe Satellite Internet Suitcase. Stay Connected Anywhere, Securely."
				vectorLineRight={false}
				model=""
				bg={heroBg}
				right={heroRightSectionImage}
				changeHeroBg={false}
			/>
			<div className="tw-flex tw-flex-col tw-gap-y-2 content_container">
				<h1 className="tw-font-goodTime tw-text-themeRed tw-text-center fz-32 tw-mb-4 tw-mt-10">
					Introducing the Enigma{" "}
					<span className="tw-text-white">Frontier Link</span>
				</h1>
				<p className="tw-text-white tw-text-justify tw-font-thin fz-20 tw-pb-10">
					Introducing the Enigma AI Network's latest innovation for secure and
					mobile communication: the Quantum Safe Satellite Internet Suitcase. In
					the vast expanses of our world, from the remote deserts to the high
					seas, staying connected and secure has always been a challenge—until
					now. Our military-grade mobile antenna, housed in a robust and
					portable suitcase, ensures that you remain in touch and protected, no
					matter where your mission takes you.
				</p>
			</div>
			{/* Second Section */}
			<div
				className="content_container lg:tw-min-h-[593px] tw-h-fit tw-flex tw-w-full tw-items-center"
				style={{
					backgroundImage: `url(${unbreakableDataImg})`,
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					// height: "100%",
				}}
			>
				<div className="tw-flex md:tw-justify-end tw-justify-center tw-items-center tw-h-full tw-w-full lg:tw-justify-self-end">
					<div className="detailsDiv lg:tw-w-[645px] lg:tw-px-0 tw-px-[9vw]">
						<div className="tw-space-y-5">
							<h1 className="tw-font-goodTime tw-text-white fz-40 md:tw-text-start tw-text-center">
								<span className="tw-text-themeRed">Quantum Safe Tech:</span>{" "}
								<br />
								Unbreakable Data
							</h1>
							<p className="tw-text-white tw-text-justify tw-font-thin fz-28">
								At the heart of our solution is our pioneering Quantum Safe
								Technology. Designed to withstand the threats of tomorrow,
								including the advent of quantum computing, our technology ensures
								that your data remains unbreakable. This isn't just a promise;
								it's a new standard in data security, tailored for the rigorous
								demands of government operations and enterprise-level
								communications.
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* third section */}
			<div
				className="tw-py-20"
				style={{
					backgroundImage: `url(${digitalCommunication})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			>
				<div className="content_container tw-flex tw-flex-col tw-gap-y-2  md:tw-space-y-5">
					<h1 className="tw-font-goodTime tw-text-white fz-32 tw-text-center">
						<span className="tw-text-themeRed">
							Your Gateway to the Future of
						</span>{" "}
						<br />
						Digital Communication
					</h1>
					<p className="tw-text-white tw-text-justify tw-font-thin fz-20">
						The future is unpredictable, but with the Enigma AI Network, you're
						always one step ahead. Our suitcase antenna system is your gateway to
						secure, efficient, and reliable communication, anywhere in the world.
						Whether you're navigating the complexities of international diplomacy
						or leading critical remote operations, our solution ensures that your
						communication lines remain open, secure, and undisrupted.
					</p>
				</div>
			</div>

			{/* Fourth Section */}
			<div className="content_container">
				<div className="tw-pt-20 md:tw-grid tw-gap-20 tw-grid-cols-3 md:tw-space-y-0 tw-space-y-5">


					<div className="tw-space-y-5 tw-col-span-2">
						<h1 className="tw-font-goodTime tw-text-white fz-32 md:tw-text-start tw-text-center">
							<span className="tw-text-themeRed">Key</span> Features:
						</h1>
						<p className="tw-text-white tw-font-thin fz-20 tw-ms-5">
							<ul
								style={{
									listStyleType: "square",
								}}
								className="tw-space-y-2 tw-text-justify"
							>
								<li>
									Military-Grade Portability: Our suitcase antenna is built to
									military specifications, ensuring durability and reliability in
									the harshest environments.
								</li>
								<li>
									Quantum Secured AI Network: Leveraging the latest in AI and
									quantum-safe encryption, we provide a secure channel immune to
									the future threats posed by quantum computing.
								</li>
								<li>
									Global Connectivity: Designed for those who operate beyond the
									reach of traditional networks, our solution keeps you connected
									in the most remote locations.
								</li>
								<li>
									Unparalleled Security: With end-to-end encryption, your
									communications—voice, video, chats, and document sharing—are
									protected against any breach.
								</li>
								<li>
									AI-Powered Features: From content moderation to anomaly
									detection, our AI ensures a safe, efficient, and personalized
									communication experience.
								</li>
							</ul>
						</p>
					</div>
					<div className="tw-flex tw-justify-end">
						<img src={heroRightSectionImage} alt="" className="tw-w-full" />
					</div>
				</div>
			</div>

			{/* fifth Section */}
			<div
				className="md:tw-pt-32 tw-pt-20 tw-pb-10 md:tw-min-h-[500px] tw-flex tw-flex-col tw-justify-center"
				style={{
					backgroundImage: `url(${earthBg})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "top center",
				}}
			>
				<div className="tw-space-y-5 content_container">

					<h1 className="tw-font-goodTime tw-text-white fz-32 tw-text-center">
						<span className="tw-text-themeRed">Engage with Enigma:</span> <br />
						Secure Your Communications Today
					</h1>
					<p className="tw-text-white tw-text-justify tw-font-thin fz-20">
						The Enigma AI Network invites you to experience the future of secure
						communication. Contact us for a demonstration and see firsthand how
						our Quantum Safe Satellite Internet Suitecase can meet your needs for
						secure, mobile, and reliable communication, even in the most remote
						corners of the globe. Join us on this journey towards secure,
						efficient, and intelligent communication. With Enigma, cutting-edge
						technology meets unparalleled security.
					</p>
				</div>
			</div>
			
			<div className="tw-py-[25px]">
				<ContactUs />
			</div>
		</div>
	);
}

export default FrontierLink;
