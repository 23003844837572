import React, { useState, useRef, useEffect } from "react";
import arrowIcon from "../../assets/icons/roadMap/arrowIcon.png";

import ContactUs from '../../components/contactUs/ContactUs';
import { hover } from "@testing-library/user-event/dist/hover";

function OurRoadmap() {
	const [activeSection, setActiveSection] = useState('1');
	const [paddingClass, setPaddingClass] = React.useState('tw-py-16');
	const [hideTitle, setHideTitle] = useState(false);

	// Refs to the section elements
	const phase1Ref = useRef(null);
	const phase2Ref = useRef(null);
	const phase3Ref = useRef(null);
	const phase4Ref = useRef(null);

	// Function to handle scrolling to a section
	const handleSectionClick = (section) => {
		setActiveSection(section);
		let targetScrollPosition = 0;

		switch (section) {
			case '1':
				targetScrollPosition = phase1Ref.current.offsetTop - 250;
				break;
			case '2':
				targetScrollPosition = phase2Ref.current.offsetTop - 250;
				break;
			case '3':
				targetScrollPosition = phase3Ref.current.offsetTop - 250;
				break;
			case '4':
				targetScrollPosition = phase4Ref.current.offsetTop - 250;
				break;
			default:
				break;
		}

		window.scrollTo({
			top: targetScrollPosition,
			behavior: 'smooth'
		});
	};

	// Function to handle scroll and set active section based on visibility
	const handleScroll = () => {
		if (window.scrollY >= 90) {
			setHideTitle(true);
		}
		else {
			setHideTitle(false);
		}
		const sections = [
			{ section: '1', ref: phase1Ref },
			{ section: '2', ref: phase2Ref },
			{ section: '3', ref: phase3Ref },
			{ section: '4', ref: phase4Ref }
		];

		for (const { section, ref } of sections) {
			if (ref.current && ref.current.getBoundingClientRect().top < window.innerHeight / 2) {
				setActiveSection(section);
			}
		}
	};

	useEffect(() => {
		// Add scroll event listener
		window.addEventListener('scroll', handleScroll);
		return () => {
			// Remove scroll event listener on component unmount
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	React.useEffect(() => {
		const handleScroll = () => {
			const isScrollPastNavbar = window.scrollY > 90; // Adjust 90 based on your navbar height
			const newPaddingClass = isScrollPastNavbar ? 'tw-py-8' : 'tw-py-16';
			setPaddingClass(newPaddingClass);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<div className={`tw-bg-[#D11C1C] tw-z-[10] tw-w-full md:tw-mt-[90px] tw-mt-[60px] tw-fixed tw-top-0 tw-flex tw-flex-col tw-items-center ${paddingClass}`} style={{ transition: 'padding 0.3s linear' }}>
				<div className="content_container tw-mb-5">
					<h1 className={`${hideTitle && 'disappear'} tw-font-goodTime tw-text-white fz-36 tw-text-center`}>
						Our Roadmap
					</h1>
				</div>
				<div className={`content_container ${hideTitle && 'tw-relative md:tw-bottom-[2vw] tw-bottom-[3.5vw]'}`}>
					<ul className={`md:tw-text-[20px] sm:tw-text-[16px] tw-text-[12px] w-text-white tw-font-bold tw-flex tw-justify-center tw-items-center tw-gap-3 tw-text-[rgba(255,255,255,0.5)]`}>
						<li className={`hover:tw-cursor-pointer ${activeSection === "1" && 'tw-text-white'} sm:tw-text-start tw-text-center hover:tw-text-white`} onClick={() => handleSectionClick('1')}>Phase 1</li>
						<li>
							<img src={arrowIcon} alt="Arrow Icon" />
						</li>
						<li className={`hover:tw-cursor-pointer ${activeSection === "2" && 'tw-text-white'} sm:tw-text-start tw-text-center hover:tw-text-white`} onClick={() => handleSectionClick('2')}>Phase 2</li>
						<li>
							<img src={arrowIcon} alt="Arrow Icon" />
						</li>
						<li className={`hover:tw-cursor-pointer ${activeSection === "3" && 'tw-text-white'} sm:tw-text-start tw-text-center hover:tw-text-white`} onClick={() => handleSectionClick('3')}>Phase 3</li>
						<li>
							<img src={arrowIcon} alt="Arrow Icon" />
						</li>
						<li className={`hover:tw-cursor-pointer ${activeSection === "4" && 'tw-text-white'} sm:tw-text-start tw-text-center hover:tw-text-white`} onClick={() => handleSectionClick('4')}>Phase 4</li>
					</ul>
				</div>
			</div>
			<div className={`content_container tw-space-y-5 tw-text-[#9e9e9e]`}>
				<div className="roadmapDivOne md:tw-grid tw-grid-cols-2 md:tw-pt-[340px] tw-pt-[300px]" ref={phase1Ref}>
					<div className="tw-flex tw-gap-5 tw-justify-end md:-tw-mr-5 tw-mr-0">
						<div className={`tw-text-white detailsDiv tw-flex tw-flex-col tw-items-end tw-gap-5 tw-pb-5`}>
							<h1 className="md:tw-text-[1.5vw] tw-text-[4.2vw]">
								Phase 1
							</h1>
							<ul className="md:tw-text-[20px] tw-text-[18px] w-text-white tw-font-light  tw-space-y-2">
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Exploration of Enigma Hardware and Software Prerequisites
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Prototyping and continuous Testing of PCB design
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Engineering Investigation
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Development of technology Patents
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Filing 10 provisional patents in the United States and
										Canada
									</span>
								</li>
							</ul>
						</div>
						<div className="tw-space-y-[20px] md:tw-block tw-hidden">
							<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full"></div>
							<div className="tw-w-[1px] tw-h-[calc(100%-55px)] tw-bg-[#d11c1c] tw-m-auto"></div>
						</div>
					</div>
					<div className="md:tw-block tw-hidden"></div>
				</div>
				<div className="md:tw-hidden tw-flex tw-justify-between tw-items-center tw-gap-5">
					<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full tw-shrink-0"></div>
					<div className="tw-h-[1px] tw-w-[calc(100%)] tw-bg-[#d11c1c]"></div>
					<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full tw-shrink-0"></div>
				</div>
				<div className="roadmapDivTwo md:tw-grid tw-grid-cols-2" ref={phase2Ref}>
					<div className="md:tw-block tw-hidden"></div>
					<div className="tw-flex tw-gap-5 tw-w-fit tw-flex-row-reverse md:-tw-ml-[0.8rem] tw-ml-0">
						<div className={`detailsDiv tw-flex tw-flex-col  tw-gap-5 tw-pb-5 tw-text-white`} onClick={() => handleSectionClick('2')}>
							<h1 className=" md:tw-text-[1.5vw] tw-text-[4.2vw]">
								Phase 2
							</h1>
							<ul className="md:tw-text-[20px] tw-text-[18px] w-text-white tw-font-light  tw-space-y-2">
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>
										Form Core Sales and Marketing Team and Advisory Board
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>Establish Corporate Structure</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>Launch Website</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>Create Marketing Collaterals</span>
								</li>
							</ul>
						</div>
						<div className="tw-space-y-[20px] md:tw-block tw-hidden">
							<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full"></div>
							<div className="tw-w-[1px] tw-h-[calc(100%-55px)] tw-bg-[#d11c1c] tw-m-auto"></div>
						</div>
					</div>
				</div>
				<div className="md:tw-hidden tw-flex tw-justify-between tw-items-center tw-gap-5">
					<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full tw-shrink-0"></div>
					<div className="tw-h-[1px] tw-w-[calc(100%)] tw-bg-[#d11c1c]"></div>
					<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full tw-shrink-0"></div>
				</div>
				<div className="roadmapDivThree md:tw-grid tw-grid-cols-2" ref={phase3Ref}>
					<div className="tw-flex tw-gap-5 tw-justify-end md:-tw-mr-5 tw-mr-0">
						<div className={`detailsDiv tw-flex tw-flex-col tw-items-end tw-gap-5 tw-pb-5 tw-text-white`}>
							<h1 className=" md:tw-text-[1.5vw] tw-text-[4.2vw]">
								Phase 3
							</h1>
							<ul className="md:tw-text-[20px] tw-text-[18px] w-text-white tw-font-light  tw-space-y-2">
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Conduct Research and Development for Post-Quantum Algorithm
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Design Quantum-Ready Enigma Key USB Hardware
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Develop Quantum-Ready Enigma SSD Hardware
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Create Enigma Enterprise Portal
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Design Quantum-Ready Secure Mobile Application
									</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Implement the Enigma Platform within the Echo System of
										CyberClan
									</span>
								</li>
								<li className="tw-flex tw-gap-5 tw-flex-row-reverse">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span className="tw-text-right">
										Identify opportunity in certain verticals and secure clients
										in the realms of healthcare, government, and legal sectors.
									</span>
								</li>
							</ul>
						</div>
						<div className="tw-space-y-[20px] md:tw-block tw-hidden">
							<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full"></div>
							<div className="tw-w-[1px] tw-h-[calc(100%-55px)] tw-bg-[#d11c1c] tw-m-auto"></div>
						</div>
					</div>
					<div className="md:tw-block tw-hidden"></div>
				</div>
				<div className={`md:tw-hidden tw-flex tw-justify-between tw-items-center tw-gap-5`}>
					<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full tw-shrink-0"></div>
					<div className="tw-h-[1px] tw-w-[calc(100%)] tw-bg-[#d11c1c]"></div>
					<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full tw-shrink-0"></div>
				</div>
				<div className={`roadmapDivFour md:tw-grid tw-grid-cols-2 tw-text-white`} ref={phase4Ref}>
					<div className="md:tw-block tw-hidden"></div>
					<div className="tw-flex tw-gap-5 tw-w-fit tw-flex-row-reverse md:-tw-ml-[0.8rem] tw-ml-0">
						<div className="detailsDiv tw-flex tw-flex-col  tw-gap-5 tw-pb-5">
							<h1 className=" md:tw-text-[1.5vw] tw-text-[4.2vw]">
								Phase 4
							</h1>
							<ul className="md:tw-text-[20px] tw-text-[18px] w-text-white tw-font-light  tw-space-y-2">
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>Design Quantum-Ready Enigma Watch</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>Design Quantum-Ready Enigma Smart Glasses</span>
								</li>
								<li className="tw-flex lg:tw-gap-5 tw-gap-3">
									<div className="tw-w-[25px] tw-h-[25px] tw-border-[1px] tw-border-[#d11c1c] tw-rounded-full tw-flex-shrink-0"></div>
									<span>
										Research and Development into Artificial Intelligence
									</span>
								</li>
							</ul>
						</div>
						<div className="tw-space-y-[20px] md:tw-block tw-hidden">
							<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full"></div>
							<div className="tw-w-[1px] tw-h-[calc(100%-55px)] tw-bg-[#d11c1c] tw-m-auto"></div>
							<div className="tw-w-[35px] tw-h-[35px] tw-bg-[#d11c1c] tw-rounded-full"></div>
						</div>
					</div>
				</div>
			</div>
			<br />
			<br />
			<br />

			<div className="tw-py-[25px]">
				<ContactUs />
			</div>
		</>
	);
}

export default OurRoadmap;
