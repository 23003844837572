import React from 'react';
// import { useSelector } from 'react-redux';

// import { roundedKeyData } from '../../data/key/roundedKey';
import roundedKey from '../../assets/icons/key/roundedKey.png';

export default function RoundedKey(props) {
    // const { screenWidth } = useSelector(state => state.responsive)

    // const calculatePosition = (index, total, radius) => {
    //     const angle = (index / total) * 2 * Math.PI; // angle in radians
    //     const x = radius * Math.cos(angle) + radius; // Adjusted for CSS positioning
    //     const y = radius * Math.sin(angle) + radius; // Adjusted for CSS positioning
    //     return { left: `${x * 1.2}px`, top: `${y * 0.8}px` };
    // };
    // const height = Math.floor(screenWidth / 2.2).toFixed(0);

    return (
        <img src={roundedKey} alt="key" className='tw-mx-auto' />
        // <div className={`content_container tw-grid tw-place-items-center tw-w-full tw-relative`}>
        //     <img src={props.centerPic} alt="key" className='tw-mx-auto tw-max-w-[300px] tw-absolute' />
        //     <div style={{ height: `${height / 0.9}px` }} className='tw-relative tw-grid tw-w-full tw-mx-auto'>
        //         {roundedKeyData.map((data, index) =>
        //             <div key={data.id} className={`tw-flex tw-box-content tw-absolute tw-items-center tw-flex-col tw-ml-[15vw] `} style={calculatePosition(index, roundedKeyData.length, screenWidth / 5.5)}>
        //                 <img src={data.icon} alt="icon" className='tw-w-[12.5vw]' />
        //             </div>
        //         )}
        //     </div>
        // </div>
    )
}
