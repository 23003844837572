import React from 'react';
import { useSelector } from 'react-redux';

// assets
import aboutUsHeroBg from '../../assets/bgs/hero/aboutUsHeroBg.svg';
import waves from '../../assets/bgs/waves.svg';
import fingerprint from '../../assets/icons/about/fingerprint.png';
import { weServe } from '../../data/aboutUs/weServe';
import userPic from '../../assets/icons/about/userPic.png';
import enigmaPlatform from '../../assets/icons/about/enigmaPlatform.svg';

// components
import ContactUs from '../../components/contactUs/ContactUs';

export default function AboutUs() {
    // globals
    const { screenWidth } = useSelector(state => state.responsive);

    return (
        <div className='tw-relative tw-bg-lightDark'>
            <div className='md:tw-h-[85vh] tw-h-[40vh] tw-w-full tw-pt-20 md:tw-mb-20 tw-flex tw-flex-col tw-justify-end tw-relative'
                style={{
                    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${aboutUsHeroBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="content_container tw-z-10">
                    <h1 className='fz-32 tw-w-full tw-font-semibold tw-text-white xl:tw-pr-[40%] tw-pr-0 md:tw-text-left tw-text-center'>Defending against Adversarial Actions while Safeguarding Data with Next Generation Data-Driven Controls.</h1>
                </div>
                <img src={waves} alt="waves" className='md:tw-top-[45vh] tw-top-[20vh] tw-w-[100vw] tw-absolute' />
                {/* <div className='tw-w-full tw-h-8 tw-bottom-0 tw-left-0 tw-bg-dullGradient md:tw-block tw-hidden'></div> */}
            </div>
            {/* <img src={waves} alt="waves" className='-tw-mt-[400px] tw-w-[100vw] lg:tw-block tw-hidden' /> */}

            <div className='content_container tw-grid md:tw-grid-cols-3 tw-grid-cols-1 md:tw-text-left tw-text-justify'>
                <div className='tw-flex tw-flex-col md:tw-items-start tw-items-center tw-gap-y-2 md:tw-col-span-2 tw-col-span-1 tw-w-full tw-pt-10'>
                    <h1 className='tw-text-[80px] tw-text-themeRed tw-font-goodTime'>About Enigma</h1>

                    <p className='fz-20 tw-text-white tw-font-thin'>Enigma is developing very unique proprietary hardware encryption platform along with a US provisional patent submission leveraging both artificial intelligence in the PKI distribution life cycle and blockchain to secure and manage the lifecycle of the product.</p>

                    <div className='tw-col-span-1 tw-flex md:tw-hidden md:tw-justify-end tw-justify-center tw-items-center'>
                        <img src={fingerprint} alt="fingerprint" className="tw-w-[50%]" />
                    </div>

                    <p className='fz-20 tw-text-white tw-font-thin'>Enigma Key will provide these solutions to clients in Defense, Government, Intelligence, Critical Infrastructure, Military, Defense, Healthcare, Manufacturing, and NATO partners on a subscription model pricing included professional services with the deployment of the latest hardware encryption keys, fully managed in-house with our internal experts to ensure a successful customer experience.</p>
                </div>
                <div className='tw-col-span-1 md:tw-flex tw-hidden md:tw-justify-end tw-justify-center tw-items-center'>
                    <img src={fingerprint} alt="fingerprint" />
                </div>
            </div>

            <div className="tw-bg-contactUsGradient tw-py-8">
                <div className='content_container tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-10'>
                    <h1 className='fz-48 tw-font-goodTime tw-text-themeRed md:tw-col-span-3 tw-col-span-1 tw-text-center tw-pt-10'>we serve</h1>
                    <div className='md:tw-col-span-3 tw-grid md:tw-grid-cols-3 tw-grid-cols-1'>
                        {weServe.map(data =>
                            <div key={data.id} className={`tw-flex tw-flex-col tw-gap-y-2 tw-py-[1.5vw] tw-col-span-1 tw-text-white tw-items-center tw-border-themeRed ${screenWidth < 768 ? 'tw-border-b-[1px]' : (data.id === 3 || data.id === 6) ? `${data.id <= 3 && 'tw-border-b-[1px]'}` : `${data.id <= 3 && 'tw-border-b-[1px]'} tw-border-r-[1px]`}`}>
                                <img src={data.icon} alt="icon" className='md:tw-w-[7vw] tw-w-[15vw]' />
                                <h5 className='md:tw-text-[1.5vw] tw-text-[3.5vw]'>{data.title}</h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='content_container md:tw-text-center tw-text-justify'>
                <h1 className='fz-48 tw-font-goodTime tw-text-themeRed tw-text-center tw-pt-10'>Our Team</h1>

                <p className='fz-20 tw-text-white tw-font-thin'>Our team, a diverse ensemble of forward-thinking innovators, is the driving force behind the creation of the groundbreaking Enigma hardwares and our suite of sophisticated mobile applications. Each member brings a wealth of expertise, dedication, and passion, ensuring that every product we craft meets the highest standards of excellence. Beyond product creation, our team is deeply immersed in research and development, continuously pushing boundaries and redefining the landscape of digital security and technology. Together, we're committed to delivering solutions that empower users and set new benchmarks for the industry.</p>
            </div>

            <div className='content_container tw-flex tw-flex-col tw-gap-y-2 md:tw-text-left tw-text-justify'>
                <h1 className='fz-48 tw-font-goodTime tw-text-themeRed tw-text-left tw-pt-10'><span className='tw-block'>About the Founder and Inventor:</span><span>Richard D'souza</span></h1>

                <div className='tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1 tw-h-fit'>
                    <div className='lg:tw-col-span-2 tw-flex tw-flex-col tw-gap-5'>
                        <p className='fz-20 tw-text-white tw-font-thin'>Richard D'souza, the visionary behind Enigma Key, dedicates his illustrious career to creating advanced cybersecurity solutions that prioritize customer needs. With over 20 years of experience in the field, Richard has consistently innovated to stay ahead of emerging threats and provide the highest levels of security for his clients.
                        </p>
                        <img src={userPic} alt="userPic" className='max-[450px]:tw-block tw-hidden tw-h-fit glow-effect' />
                        <p className='fz-20 tw-text-white tw-font-thin'>
                            Richard's expertise spans a wide range of cybersecurity areas, including incident response services, computer forensics, secure architecture, security assessments, audits, cyber extortion, and dark web investigations. His extensive knowledge and hands-on experience have equipped him with a deep understanding of the challenges faced by organizations in various sectors.
                        </p>
                    </div>
                    <div className='tw-col-span-1 min-[450px]:tw-flex tw-hidden md:tw-justify-end tw-justify-center md:tw-mt-0 tw-mt-5'>
                        {/* <div className="glow-effect"> */}
                            <img src={userPic} alt="userPic" style={{ width: "auto", height: "90%" }} className='glow-effect' />
                        {/* </div> */}
                    </div>

                </div>

                <div className=''>
                    <img src={enigmaPlatform} alt="waves" className="max-[450px]:tw-block tw-hidden" />
                    <p className='fz-20 tw-text-white md:tw-text-left tw-text-justify tw-font-thin tw-pb-10'>As the founder and owner of Enigma Key, Richard's mission is to deliver customer-centric security solutions that protect sensitive information and ensure the integrity and confidentiality of users’ data. By leveraging the power of artificial intelligence and blockchain technology, Enigma Key's proprietary hardware encryption platform offers unparalleled security to clients in the defense, government, intelligence, critical infrastructure, military, healthcare, manufacturing, and NATO partner sectors.  <br /><br />Your security is our top priority. Trust Enigma Key and Richard D'souza to provide the most advanced and customer-focused security solutions available today.</p>
                </div>
            </div>

            <img src={enigmaPlatform} alt="waves" className="min-[450px]:tw-block tw-hidden tw-w-screen" />

            <div className='tw-py-[25px]'>
                <ContactUs />
            </div>

        </div>
    )
}
