import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import 'intl-tel-input';

export default function ContactUs(props) {
    // locals
    const options = window.intlTelInputGlobals.getCountryData().map(code => {
        return {
            value: `+${code.dialCode}`,
            label: (
                <div className="tw-flex tw-items-center tw-gap-x-2 fz-20">
                    <img src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code.iso2.toUpperCase()}.svg`} alt="" style={{ width: '20px', height: '20px' }} />
                    <p style={{ fontSize: '20px !important' }} className='fz-20'>+{code.dialCode}</p>
                </div>
            )
        }
    });
    const [query, setQuery] = useState({
        name: '',
        email: '',
        country: '',
        phone: '',
        option: '',
        message: '',
    });

    const changeHandler = (event) => {
        setQuery({...query, [event.target.name]: event.target.value});
    };

    const changeHandlerSelect = (selectedOption, actionMeta) => {
        // React Select passes a second argument (actionMeta) which includes the name of the input
        const name = actionMeta.name;

        if (name) { // This means the change event is from React Select
            const value = selectedOption ? selectedOption.value : ''; // Handles deselecting
            setQuery(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (selectedOption.target) { // Standard HTML input
            const { name, value } = selectedOption.target;
            setQuery(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const submitHandler = (event) => {
        if(query.name === '' || query.email === '' || query.country === '' || query.phone === '' || query.option === '' || query.message === '') {
            alert('Please fill all the fields');
            return;
        }
        axios.post('https://enigmakey.io/server/contactUs', {
            name: query.name,
            email: query.email,
            contact: query.country + query.phone,
            option: query.option,
            message: query.message
        }).then(response => {
            setQuery({
                name: '',
                email: '',
                // country: '',
                phone: '',
                // option: '',
                message: ''
            });
            alert('Submitted Successfully');
        }).catch(error => {
            alert('Failed to submit');
        })
    }

    return (
        <div className={`content_container tw-w-full dark:tw-text-white tw-text-black tw-py-[4vw] ${props.className}`}>
            <h1 className='tw-font-goodTime fz-48 tw-text-center'>Contact Us</h1>
            <div className='tw-grid tw-grid-cols-12 md:tw-gap-[1vw] tw-gap-y-[3vw] tw-mt-5'>
                <input type="text" className='md:tw-col-span-5 tw-col-span-12 tw-px-[10px] tw-py-[15px] tw-bg-transparent tw-border-[1px] tw-border-slate-200 tw-outline-none tw-rounded-md' placeholder='Name' name='name' value={query.name} onChange={changeHandler} />
                <input type="text" className='md:tw-col-span-7 tw-col-span-12 tw-px-[10px] tw-py-[15px] tw-bg-transparent tw-border-[1px] tw-border-slate-200 tw-outline-none tw-rounded-md' placeholder='example@email.com' name='email' value={query.email} onChange={changeHandler} />
                <Select
                    options={options}
                    className='md:tw-col-span-2 tw-col-span-5 tw-bg-transparent tw-rounded-md tw-text-[20px]'
                    placeholder='Country'
                    name='country'
                    // value={query.country}
                    onChange={(selectedOption, actionMeta) => changeHandlerSelect(selectedOption, actionMeta)}
                    styles={{
                        control: base => ({
                            ...base,
                            backgroundColor: 'transparent',
                            outline: 'none',
                            height: '100%',
                            color: 'white'
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#c5c5c5',
                            color: 'black',
                            fontSize: '20px !important'
                        }),
                        singleValue: (provided, state) => ({
                            ...provided,
                            color: 'white', // Set your desired color
                            fontSize: '20px !important'
                        }),
                    }}
                />
                <input type="number" className='md:tw-col-span-3 tw-col-span-7 tw-px-[10px] tw-py-[15px] tw-bg-transparent tw-border-[1px] tw-border-slate-200 tw-outline-none tw-rounded-md' placeholder='Phone' name='phone' value={query.phone} onChange={changeHandler} />
                <Select
                    name='option'
                    // value={query.option}
                    onChange={(selectedOption, actionMeta) => changeHandlerSelect(selectedOption, actionMeta)}
                    options={[
                        { value: 'enterprise', label: 'Enterprise' },
                        { value: 'nato', label: 'NATO' },
                        { value: 'government', label: 'Government' },
                        { value: 'military', label: 'Military' },
                        { value: 'healthcare', label: 'Health Care' },
                        { value: 'lawfirm', label: 'Law Firm' },
                        { value: 'federal', label: 'Federal' },
                        { value: 'provincial', label: 'Provincial' },
                        { value: 'lawenforcement', label: 'Law Enforcement' },
                        { value: 'manufacturing', label: 'Manufacturing' },
                        { value: 'agriculture', label: 'Agriculture' },
                        { value: 'industry', label: 'Industry' },
                        { value: 'mining', label: 'Mining' },
                        { value: 'retail', label: 'Retail' },
                        { value: 'construction', label: 'Construction' },
                        { value: 'management', label: 'Management' },
                        { value: 'informationtechnology', label: 'Information Technology' },
                        { value: 'telecommunications', label: 'Telecommunications' },
                        { value: 'shipping', label: 'Shipping' },
                        { value: 'partner', label: 'Partner' },
                        { value: 'others', label: 'Others' }
                    ]}
                    className='md:tw-col-span-7 tw-col-span-12 tw-bg-transparent tw-rounded-md'
                    placeholder='Select'
                    styles={{
                        control: base => ({
                            ...base,
                            backgroundColor: 'transparent',
                            outline: 'none',
                            height: '100%',
                            color: 'white'
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#c5c5c5',
                            color: 'black'
                        }),
                        singleValue: (provided, state) => ({
                            ...provided,
                            color: 'white', // Set your desired color
                        }),
                    }}
                />
                <textarea type="text" className='tw-col-span-12 tw-px-[10px] tw-py-[15px] tw-bg-transparent tw-border-[1px] tw-border-slate-200 tw-outline-none tw-rounded-md' placeholder='Message' rows={7} name='message' value={query.message} onChange={changeHandler} />

                <div className='md:tw-col-span-10 tw-col-span-12 tw-px-[10px] tw-py-[15px] tw-bg-transparent tw-border-[1px] tw-border-slate-200 tw-outline-none tw-grid tw-grid-cols-12 tw-items-center tw-rounded-md'>
                    <p className='md:tw-col-span-1 tw-col-span-4 fz-20'>4 + 2 =</p>
                    <input type="text" className='md:tw-col-span-11 tw-col-span-8 tw-border-none tw-outline-none tw-bg-transparent' placeholder='Write Answer to submit' />
                </div>

                <button className='md:tw-col-span-2 tw-col-span-12 tw-py-[15px] tw-outline-none tw-border-[1px] tw-border-slate-200 tw-rounded-md' onClick={submitHandler}>Submit</button>
            </div>
        </div>
    )
}
