import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    screenWidth: window.innerWidth
}

const responsiveSlice = createSlice({
    name: 'responsive',
    initialState,
    reducers: {
        setScreenWidth(state, action) {
            state.screenWidth = action.payload.screenWidth;
        }
    }
})

const responsiveActions = responsiveSlice.actions;

export { responsiveActions }

export default responsiveSlice;