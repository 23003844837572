import React from 'react';
import { useSelector } from 'react-redux';

// assets
import advancedEncryptionTech from '../../assets/icons/key/advancedEncryptionTech.svg';

export default function KeyDevelopmentRoadmap(props) {
    const { screenWidth } = useSelector(state => state.responsive);

    return (
        <div className={`content_container tw-overflow-x-hidden tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-items-center tw-relative tw-py-10`}>
            <div className='tw-col-span-1 tw-py-[4vw]'>
                <h1 className='fz-32 tw-font-goodTime tw-text-white tw-text-center'><span className='tw-text-themeRed tw-block'>Enigma Key</span>Development Roadmap</h1>
            </div>
            <img src={advancedEncryptionTech} alt="advanced" className={`tw-absolute md:tw-left-[30%] tw-left-[5%] md:tw-top-auto tw-top-[33%] ${screenWidth < 768 ? 'tw-w-[30vw]' : 'tw-w-[300px]'}`} />
            <div className='tw-col-span-1 tw-flex tw-flex-col tw-gap-y-[5vw] tw-py-10'
                style={{
                    backgroundImage: `url(${props.aetBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                    backgroundSize: 'cover',
                }}
            >
                {props.phases.map(phase =>
                    <div key={phase.id} className={`md:tw-p-2 tw-p-[1.5vw] tw-relative ${(phase.id === 1 || phase.id === 4) ? 'md:tw-right-[1vw] md:tw-left-auto tw-left-[25vw]' : 'md:tw-left-[4vw] tw-left-[30vw]'}`}>
                        <p className={`tw-font-goodTime tw-text-white ${screenWidth < 768 ? 'tw-text-[2.1vw]' : 'fz-28'}`}><span className='tw-text-themeRed'>PHASE {phase.id}: </span> {phase.title} <br /></p>
                        <p className='md:tw-text-[15px] tw-text-[2.5vw] tw-text-white tw-w-[75%] md:tw-w-auto'>{phase.description}</p>
                    </div>
                )
                }
            </div >
        </div >
    )
}
