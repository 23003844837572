import React from 'react';

// assets
import enigmaFont from '../../../assets/bgs/hero/enigmaFont.svg';
// import vectorRight from "../../../assets/icons/vectorRight.svg";

// components
import SimpleButton from '../../ui/simpleButton/SimpleButton';

export default function HeroTemplate(props) {
    return (
        <div className='tw-h-screen tw-w-screen content_container tw-flex tw-flex-col tw-justify-center tw-text-white'>
            <div className='tw-space-y-1'>
                <div className={`tw-flex tw-w-full ${props.boldDescription ? 'tw-flex-col tw-items-start' : 'tw-flex-col'} tw-gap-x-2`}>
                    <div className={`tw-flex tw-w-full md:tw-flex-row tw-flex-col tw-items-center tw-gap-x-2`}>
                        <img src={enigmaFont} className='tw-max-w-[300px]' alt="enigmaFont" />
                        <h1 className={`tw-font-nasa fz-60 md:tw-mt-4`}>{props.title}</h1>
                    </div>
                </div>
                <p className={`tw-font-nasa fz-32 tw-w-full md:tw-w-[60%] tw-text-center md:tw-text-left`}>{props.boldDescription}</p>
                <h1 className='md:tw-w-[45vw] fz-20 tw-text-center md:tw-text-left tw-w-full tw-font-thin'>{props.description}</h1>
                {props.productImage && <img src={props.productImage} className='md:tw-mx-0 tw-mx-auto md:tw-w-auto tw-w-[200px]' alt={props.title} />}
                {props.id === 1 && <div className="tw-flex tw-items-center tw-gap-x-2 -tw-mt-4">
                    {/* <img
                        src={vectorRight}
                        alt="vector"
                        className="tw-w-[70%] md:tw-w-[18%]"
                    /> */}
                    {/* <p className="tw-text-themeRed tw-font-semibold fz-20 tw-whitespace-nowrap">
                        Model 20232
                    </p> */}
                </div>
                }
                <SimpleButton text={`More About Enigma ${props.title} ${props.id === 3 ? "Secured Network" : ""}`} className='tw-mx-auto md:tw-mx-0 tw-mt-5' navigate={props.navigate} />
            </div>
        </div>
    )
}
