import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    inDarkMode: true
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleDarkMode(state) {
            state.inDarkMode = !state.inDarkMode;
        }
    }
})

export default uiSlice;

export const { toggleDarkMode } = uiSlice.actions;