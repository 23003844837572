import blockchainTechnologyBg from '../../assets/bgs/authSwiper/blockchainTechnologyBg.svg';
import artificialIntelligenceBg from '../../assets/bgs/authSwiper/artificialIntelligenceBg.svg';
import blockchain from '../../assets/icons/authSwiper/blockchain.png';
import ai from '../../assets/icons/authSwiper/ai.png';
import encryptionBg from '../../assets/bgs/authSwiper/encryptionBg.svg';
import encryption from '../../assets/icons/authSwiper/encryption.png';

export const swiperData = [
    {
        id: 1,
        title: 'Blockchain Technology',
        bg: blockchainTechnologyBg,
        icon: blockchain,
    },
    {
        id: 2,
        title: 'Artificial Intelligence (AI)',
        bg: artificialIntelligenceBg,
        icon: ai,
    },
    {
        id: 3,
        title: 'Quantum Safe Encryption',
        bg: encryptionBg,
        icon: encryption,
    }
]