import React from 'react';

// assets
import bg from '../../assets/icons/key/bg.svg';
import right from '../../assets/icons/ssd/rightWithLine.svg';
import aetBg from '../../assets/icons/ssd/aetBg.svg';
import { roadmap } from '../../data/ssd/roadmap';
import cloudInfraBg from '../../assets/bgs/ssd/cloudInfraBg.svg';
import usb from '../../assets/icons/ssd/usb.svg';
import dimensions from '../../assets/icons/ssd/dimensions.svg';
import shield from '../../assets/icons/ssd/shield.svg';
import secFeatures from '../../assets/bgs/ssd/secFeatures.png';
import { useSelector } from 'react-redux';

// components
import ProductsHero from '../../components/hero/productsHero/ProductsHero';
import EncryptionTechnologies from '../../components/encryptionTechnologies/EncryptionTechnologies';
import SsdRoadmap from '../../components/ssdRoadmap/SsdRoadmap';
import ContactUs from '../../components/contactUs/ContactUs';
import RoadMap from '../../components/roadMap/RoadMap';
import RoundedKey from '../../components/roundedKey/RoundedKey';

export default function EnigmaSsd() {
    const { screenWidth } = useSelector(state => state.responsive);
    return (
        <div className='tw-bg-lightDark'>
            <ProductsHero
                title="SSD"
                title2="The Pinnacle of Quantum-Safe, Unbreakable Data Storage"
                description="Enigma SSD: Military-Grade Encryption with Post-Quantum, RSA, ECC, & AES Technologies. Designed for Military, Healthcare, Manufacturing, and NATO Partners."
                model='Model 30230'
                vectorLineRight={true}
                bg={bg}
                right={right}
            />

            <div className='tw-flex tw-flex-col tw-gap-y-2 md:tw-text-[1.2vw] tw-text-[3.5vw] content_container'>
                <h1 className='tw-font-goodTime tw-text-themeRed tw-text-center fz-32 tw-pt-10'>Introducing the Enigma SSD</h1>
                <p className='tw-text-white md:tw-text-left tw-text-justify tw-pb-10 fz-20'>
                    The Enigma SSD is a cutting-edge storage solution, designed for entities demanding the highest level of data security such as Defense, Government, Intelligence, and Critical Infrastructure. Developed by CyberClan's founder, it's a part of the Enigma product suite that includes the EnigmaKey and EnigmaConsole Management Service. This SSD is not just a storage device; it's a testament to advanced, military-grade hardware encryption and authentication technology.
                </p>
            </div>

            <EncryptionTechnologies
                technologies={[
                    {
                        id: 1,
                        title: 'Post Quantum Cryptography',
                        short: 'PQC',
                    },
                    {
                        id: 2,
                        title: "Rivest Shamir Adleman",
                        short: 'RSA',
                    },
                    {
                        id: 3,
                        title: 'Elliptic Curve Cryptography',
                        short: 'ECC',
                    },
                    {
                        id: 4,
                        title: 'Advanced Encryption Standard',
                        short: 'AES',
                    }
                ]}
                aetBg={aetBg}
            />

            {/* <SsdRoadmap
                data={roadmap}
                coloredTitle="Enigma SSD"
                title="Development Roadmap"
                block={true}
            /> */}

            <RoadMap />

            <RoundedKey centerPic={right} />

            <div className='content_container tw-py-[4vw] tw-flex tw-flex-col tw-gap-5'>
                <h1 className='fz-32 tw-text-white tw-font-goodTime'>Model 20232: <span className='tw-text-themeRed'>Balancing Performance and Security</span></h1>

                <div>
                    <h2 className='fz-24 tw-text-white'>Basic Security Model:</h2>
                    <p className='fz-20 tw-text-white tw-font-thin md:tw-text-left tw-text-justify'>With USB 3.0 speeds and capacities from 32GB to 128GB, this model boasts an anodized aluminum enclosure and epoxy-filled casing for ultimate protection.</p>
                </div>
                <div>
                    <h2 className='fz-24 tw-text-white'>Enterprise Security Model:</h2>
                    <p className='fz-20 tw-text-white tw-font-thin md:tw-text-left tw-text-justify'>Offers central administration of drive access and usage, password and access policy enforcement, and remote management capabilities.</p>
                </div>
            </div>

            <div className='tw-py-10 tw-min-h-[300px] tw-flex tw-items-center'
                style={{
                    backgroundImage: `url(${cloudInfraBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}
            >
                <div className="content_container tw-flex tw-flex-col tw-gap-y-4">
                    <h1 className='fz-32 tw-font-goodTime tw-text-themeRed tw-text-center'>Private <span className='tw-text-white'>Cloud</span> Infrastructure</h1>
                    <p className='fz-20 tw-text-white tw-text-center tw-font-thin'>Compatible with major IaaS providers, offering advanced security and encryption for enterprises and government organizations.</p>
                </div>
            </div>

            <div className='tw-py-10'>
                <div className='content_container  tw-text-white'>
                    <h1 className='fz-32 tw-font-goodTime tw-text-themeRed tw-text-center'>Innovative <span className='tw-text-white'>Security</span> Features</h1>

                    <ul className='tw-list-disc fz-20 tw-font-thin tw-ms-10 tw-mt-5'>
                        <li>On-device crypto chip for enhanced security.</li>
                        <li>RSA 4096, AES 128/192/256-bit encryption, and elliptic curve technologies.</li>
                        <li>Complex password and passphrase security with mandatory multi-factor authentication.</li>
                        <li>Anti-tamper detection, clone prevention technology, and pulse boost module.</li>
                    </ul>
                </div>
            </div>
            <div
                className='content_container tw-py-10 tw-minh-[400px] tw-bg-lightDark'
                style={
                    {
                        backgroundImage: `
                        url(${secFeatures})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left',
                        // backgroundSize: `${screenWidth > 768 ? 'contain' : 'cover'}`,
                        backgroundSize: `32%`,
                    }
                }>
                <div className='tw-flex tw-justify-end'>
                    <div className='tw-grid tw-grid-cols-2 tw-gap-4 md:tw-w-[75%] tw-w-full'>
                        <div className='tw-col-span-1 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-5 tw-py-3 tw-text-white tw-bg-gradient-to-r tw-from-[#ffffff66] tw-to-[#ffffff33] tw-border-[0.5px] tw-border-white tw-rounded-lg tw-justify-center'>
                            <img src={usb} alt="usb" className='tw-h-[50%]' />
                            <p className='fz-20'>Fast USB 3.0 performance.</p>
                        </div>
                        <div className='tw-col-span-1 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-5 tw-py-3 tw-text-white tw-bg-gradient-to-r tw-from-[#ffffff66] tw-to-[#ffffff33] tw-border-[0.5px] tw-border-white tw-rounded-lg tw-justify-center'>
                            <img src={dimensions} alt="dimensions" className='tw-h-[50%]' />
                            <p className='fz-20'>Compact dimensions: <span className='md:tw-block'>72.5 mm x 9 mm.</span></p>
                        </div>
                        <div className='tw-col-span-2 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-5 tw-py-3 tw-text-white tw-bg-gradient-to-r tw-from-[#ffffff66] tw-to-[#ffffff33] tw-border-[0.5px] tw-border-white tw-rounded-lg tw-justify-center'>
                            <img src={shield} alt="dimensions" className='tw-h-[50%]' />
                            <p className='fz-20'>Waterproof casing, anti-tamper conductive mesh, light sensor detection, PCB potting, and self-destruction mechanism for ultimate physical security.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="tw-py-[25px]">
                <ContactUs />
            </div>
        </div>
    )
}
