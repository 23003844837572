import React from 'react'
// import ContactUs_Form from '../../components/ContactUs_Form/ContactUs_Form'
import ContactUsForm from '../../components/contactUs/ContactUs';

function ContactUs() {
    return (
        <div className='content_container'>
            <div className='md:tw-py-24 tw-py-20'>
                <ContactUsForm />
            </div>
        </div>
    )
}

export default ContactUs