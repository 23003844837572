import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import HeroTemplate from './heroTemplate/HeroTemplate';
// import SimpleButton from '../ui/simpleButton/SimpleButton';

import { heroTemplate } from '../../data/home/heroTemplate';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

export default function Hero() {
    // globals
    const { screenWidth } = useSelector(state => state.responsive);

    // locals
    const [activeSlideIndex, setActiveSlideIndex] = useState('');

    return (
        <>
            <div className='tw-h-screen tw-w-screen tw-relative'>
                <div className='tw-h-screen tw-w-screen tw-absolute tw-top-0 tw-right-0'
                    style={{
                        backgroundImage: `url(${activeSlideIndex === 0 ? heroTemplate[0].bg : activeSlideIndex === 1 ? heroTemplate[1].bg : activeSlideIndex === 2 ? heroTemplate[2].bg : activeSlideIndex === 3 ? heroTemplate[3].bg : heroTemplate[0].bg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                </div>
                <Swiper
                    direction={screenWidth < 768 ? 'horizontal' : 'vertical'}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    style={{ height: screenWidth < 768 ? '95vh' : '85vh' }}
                    className='mainHero content_container'
                    onSlideChange={(swiper) => {
                        setActiveSlideIndex(swiper.activeIndex);
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    {heroTemplate.map(data =>
                        <SwiperSlide key={data.id}>
                            <HeroTemplate
                                bg={data.bg}
                                title={data.title}
                                description={data.description}
                                boldDescription={data.boldDescription}
                                productImage={data.productImage}
                                navigate={data.navigate}
                                id={data.id}
                            />
                        </SwiperSlide>
                    )}
                </Swiper>
                <div className='content_container tw-absolute tw-h-[72px] tw-w-full tw-m-auto tw-flex tw-flex-col md:tw-items-end tw-items-center' style={{
                    right: `var(--swiper-pagination-right, 0)`,
                    left: `var(--swiper-pagination-left, 0)`,
                    top: `${screenWidth > 768 ? '42%' : 'auto'}`,
                    bottom: `${screenWidth > 768 ? 'auto' : screenWidth < 400 ? '10px' : '50px'}`,
                    transform: "translate3d(0px, -50%, 0)"
                }}>
                    <p
                        className={`tw-text-[15px] tw-text-white tw-leading-5 tw-z-[2] md:tw-inline tw-hidden tw-mr-[75px]  ${activeSlideIndex === 0 ? 'tw-mt-[2px]' : activeSlideIndex === 1 ? 'tw-mt-[20px]' : activeSlideIndex === 2 ? 'tw-mt-[38px]' : activeSlideIndex === 3 ? 'tw-mt-[55px]' : 'tw-mt-1'
                            }`}
                    >
                        {heroTemplate[activeSlideIndex]?.slideName || 'Enigma Key'}
                    </p>
                    <p
                        className={`tw-text-[15px] tw-text-white tw-z-[2] tw-inline md:tw-hidden ${activeSlideIndex === 0 ? 'tw-mr-20' : activeSlideIndex === 1 ? 'tw-mr-16' : activeSlideIndex === 2 ? 'tw-ms-2' : activeSlideIndex === 3 ? 'tw-ms-20' : 'tw-mr-16'
                            }`}
                    >
                        {heroTemplate[activeSlideIndex]?.slideName || 'Enigma Key'}
                    </p>
                </div>
            </div >
            {/* Quantum Safe Tech . Unbreakable Data */}
            <h1 className='content_container tw-text-[#232225] tw-text-center tw-font-goodTime tw-opacity-75 tw-py-[1vw]'>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>Q</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>u</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>a</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>n</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>t</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>u</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>m </span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>S</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>a</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>f</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>e </span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>T</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>e</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>c</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>h </span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'> . </span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>U</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>n</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>b</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>r</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>e</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>a</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>k</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>a</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>b</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>l</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>e </span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>D</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>a</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>t</span>
                <span className='hover:tw-text-[#6b2022] tw-transition-colors tw-duration-300 tw-cursor-default'>a</span>
            </h1>
        </>
    )
}
