import React from 'react';

// components
import ContactUs from '../../components/contactUs/ContactUs';

export default function CyberSecurityPolicy() {
    return (
        <div className='tw-py-20 tw-bg-lightDark'>
            <div className='tw-text-white content_container'>
                <div className='tw-grid md:tw-grid-cols-3 tw-grid-cols-2 tw-w-full md:tw-mt-20 tw-mt-10'>
                    <div className="tw-col-span-2 tw-flex tw-flex-col tw-items-start">
                        <h1 className='fz-36 tw-font-goodTime md:tw-whitespace-nowrap'><span className='tw-text-themeRed tw-block md:tw-inline-block'>Cyber Security</span> Policy</h1>
                        <p className='fz-24'>Last Updated Nov 16, 2023</p>
                    </div>
                </div>

                <ol className="tw-list-decimal tw-flex tw-flex-col tw-gap-y-2 md:tw-text-[1.3vw] tw-text-[3.5] md:tw-leading-[2vw] tw-leading-[6vw] tw-mt-4">
                    <li className='fz-20'>
                        <span className="tw-font-semibold">Introduction</span>
                        <p className="fz-20 tw-font-thin">Enigma is committed to ensuring the confidentiality, integrity, and availability of its information assets. This Cybersecurity Policy outlines the principles and practices that govern our approach to cybersecurity, demonstrating our dedication to maintaining a secure and resilient business environment.</p>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Information Security Governance</span>
                        <ol className="tw-pl-4 tw-list-decimal">
                            <li className='fz-20'>
                                <span className='tw-font-semibold'>Responsibilities</span>
                                <ul className='tw-list-disc tw-list-inside'>
                                    <li className="fz-20 tw-font-thin">The Enigma Security Team (EST) is responsible for overseeing and implementing the cybersecurity program.</li>
                                    <li className="fz-20 tw-font-thin">All employees are accountable for adhering to cybersecurity policies and reporting any security incidents promptly.</li>
                                </ul>
                            </li>
                            <li className='fz-20'>
                                <span className='tw-font-semibold'>Risk Management</span>
                                <ul className='tw-list-disc tw-list-inside'>
                                    <li className="fz-20 tw-font-thin">Regular risk assessments will be conducted to identify, assess, and prioritize information security risks.</li>
                                    <li className="fz-20 tw-font-thin">Mitigation strategies will be implemented to reduce or eliminate identified risks.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Access Control</span>
                        <ol className="tw-pl-4 tw-list-decimal">
                            <li className='fz-20'>
                                <span className='tw-font-semibold'>User Access Management</span>
                                <ul className='tw-list-disc tw-list-inside'>
                                    <li className="fz-20 tw-font-thin">Access to information systems and data is granted based on job roles and responsibilities.</li>
                                    <li className="fz-20 tw-font-thin">User access privileges are reviewed regularly to ensure alignment with business needs.</li>
                                </ul>
                            </li>
                            <li className='fz-20'>
                                <span className='tw-font-semibold'>Authentication</span>
                                <ul className='tw-list-disc tw-list-inside'>
                                    <li className="fz-20 tw-font-thin">Strong password policies are enforced, including regular password changes.</li>
                                    <li className="fz-20 tw-font-thin">Multi-factor authentication (MFA) is implemented for access to critical systems and data.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Information Security Awareness and Training</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">All employees undergo cybersecurity awareness training to stay informed about the latest threats and best practices.</li>
                            <li className="fz-20 tw-font-thin">Training is provided regularly and includes phishing awareness exercises.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Data Protection and Privacy</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">Personal and sensitive information is classified and protected according to applicable data protection laws and regulations.</li>
                            <li className="fz-20 tw-font-thin">Data breaches are reported to the appropriate authorities and affected individuals as required by law.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Incident Response and Management</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">An incident response plan is in place to effectively detect, respond to, and recover from security incidents.</li>
                            <li className="fz-20 tw-font-thin">Security incidents are reported to the Enigma Security Team at (EST) and are thoroughly investigated.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Physical Security</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">Physical access to data centers and critical infrastructure is restricted and monitored.</li>
                            <li className="fz-20 tw-font-thin">Security measures such as surveillance and access controls are implemented to protect physical assets.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Network Security</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">Firewalls, intrusion detection/prevention systems, and other security controls are implemented to safeguard the network.</li>
                            <li className="fz-20 tw-font-thin">Regular security audits are conducted to identify and address potential vulnerabilities.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Security Monitoring and Testing</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">Continuous monitoring of security controls and systems is conducted to detect and respond to potential threats.</li>
                            <li className="fz-20 tw-font-thin">Regular penetration testing and vulnerability assessments are performed to identify and remediate weaknesses.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Compliance</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">Enigma is committed to complying with all relevant laws, regulations, and contractual obligations related to information security.</li>
                            <li className="fz-20 tw-font-thin">Regular compliance audits are conducted to ensure adherence to these requirements.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Review and Update</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">This cybersecurity policy will be reviewed annually and updated as necessary to address emerging threats and changes in the business environment.</li>
                        </ul>
                    </li>

                    <li className='fz-20'>
                        <span className="tw-font-semibold">Contact Information</span>
                        <ul className='tw-list-disc tw-list-inside'>
                            <li className="fz-20 tw-font-thin">For questions or concerns related to this cybersecurity policy, please contact the Enigma Security Team at (EST) compliance@enigmakey.io</li>
                        </ul>
                    </li>
                    {/* ... continue with the rest of the sections */}
                </ol>

                <div className='tw-bg-spanningGradient'>
                    <ContactUs />
                </div>
            </div>
        </div>

    )
}
