import bg2 from '../../assets/bgs/hero/bg2.svg';
import bg3 from '../../assets/bgs/hero/bg3.png';
import bg4 from '../../assets/bgs/hero/bg4.png';
import bg5 from '../../assets/bgs/hero/bg5.png';
import bg6 from '../../assets/bgs/hero/bg6.svg';
import enigmaKeyProduct from '../../assets/icons/key/rightWithLine.svg';
import enigmaSsdProduct from '../../assets/icons/ssd/rightWithLine.svg';

export const heroTemplate = [
    {
        id: 1,
        bg: bg2,
        title: 'Key',
        description: 'Military Grade Hardware Encryption and Authentication Technology using Post Quantum Cryptography (PQC), RSA, ECC & AES.',
        slideName: "Enigma Key",
        productImage: enigmaKeyProduct,
        navigate: '/enigmaKey'
    },
    {
        id: 2,
        bg: bg3,
        title: "SSD",
        boldDescription: "The Pinnacle of Quantum-Safe, Unbreakable Data Storage",
        slideName: "Enigma SSD",
        productImage: enigmaSsdProduct,
        navigate: '/enigmaSsd'
    },
    {
        id: 3,
        bg: bg4,
        title: "",
        boldDescription: "Quantum Safe AI Network",        
        description: "Revolutionizing Secure Communication",
        slideName: "Enigma Secured Network",
        navigate: '/enigmaQuantumSafe'
    },
    {
        id: 4,
        bg: bg5,
        title: "Frontier Link",
        description: "Military Grade Hardware Encryption and Authentication Technology using Post Quantum Cryptography (PQC), RSA, ECC & AES.",
        slideName: "Enigma Frontier Link",
        navigate: '/frontierLink'
    }
]