import antiTemper from '../../assets/icons/features/antiTemper1.svg';
import clone from '../../assets/icons/features/clone1.svg';
import encTech from '../../assets/icons/features/encTech1.svg';
import militaryGrade from '../../assets/icons/features/militaryGrade1.svg';
import multiFactor from '../../assets/icons/features/multiFactor1.svg';
import selfDestruction from '../../assets/icons/features/selfDestruction1.svg';
import unbreakableData from '../../assets/icons/features/unbreakableData1.svg';
import antiTemperDark from '../../assets/icons/features/antiTemperDark.svg';
import cloneDark from '../../assets/icons/features/cloneDark.svg';
import encTechDark from '../../assets/icons/features/encTechDark.svg';
import militaryGradeDark from '../../assets/icons/features/militaryGradeDark.svg';
import multiFactorDark from '../../assets/icons/features/multiFactorDark.svg';
import selfDestructionDark from '../../assets/icons/features/selfDestructionDark.svg';
import unbreakableDataDark from '../../assets/icons/features/unbreakableDataDark.svg';

export const features = [
    {
        id: 1,
        src: militaryGrade,
        title: "Post-Quantum Encryption using Artificial Intelligence",
        darkSrc: militaryGradeDark
    },
    {
        id: 2,
        src: encTech,
        title: "Patented Encryption Technology",
        darkSrc: encTechDark
    },
    {
        id: 3,
        src: multiFactor,
        title: "Multifactor Authentication with Artificial Intelligence & Machine Learning",
        darkSrc: multiFactorDark
    },
    {
        id: 4,
        src: unbreakableData,
        title: "Unbreakable Data",
        darkSrc: unbreakableDataDark
    },
    {
        id: 5,
        src: antiTemper,
        title: "Anti-Tamper Detection Module Technology",
        darkSrc: antiTemperDark
    },
    {
        id: 6,
        src: selfDestruction,
        title: "Self-Destruction Technology",
        darkSrc: selfDestructionDark
    },
    {
        id: 7,
        src: clone,
        title: "Clone Prevention Technology",
        darkSrc: cloneDark
    }
]