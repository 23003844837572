import React from 'react';

// assets
import carot from '../../assets/icons/key/carot.svg';

export default function TitleBullets(props) {
    return (
        <div className='tw-flex tw-flex-col tw-gap-y-2 tw-text-white'>
            <h1 className='fz-32 tw-font-goodTime'><span className={`tw-text-themeRed ${props.block && 'tw-block'}`}>{props.coloredTitle}</span> {props.title}</h1>

            {props.bullets.map((bullet, index) =>
                <div className='tw-flex tw-items-center tw-gap-x-2'>
                    <img src={carot} alt="carot" />
                    <p className='fz-20'>{bullet}</p>
                </div>
            )}
        </div>
    )
}
