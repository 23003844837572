import React from 'react';

// assets
import enigmaApplicationBg from '../../assets/bgs/enigmaApplicationBg.svg';
import enigmaApplication from '../../assets/download/enigmaApplication.svg';
import enigmaFont from '../../assets/bgs/hero/enigmaFont.svg';
import google from '../../assets/download/google.svg';
import appStore from '../../assets/download/appStore.svg';

export default function EnigmaApplication() {
    return (
        <div
            style={{
                backgroundImage: `url(${enigmaApplicationBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
            className='tw-pb-[75px]'
        >
            <div className='tw-w-full content_container tw-pt-[4vw] dark:tw-text-white tw-text-black tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-5 tw-items-center'>
                <img src={enigmaApplication} alt="app" className='tw-col-span-1 md:tw-order-1 tw-order-last' />
                <div className='tw-flex tw-flex-col md:tw-hidden tw-items-center tw-gap-2 tw-order-last tw-col-span-1'>
                    <img src={google} alt="google" />
                    <img src={appStore} alt="apple" />
                </div>
                <div className='md:tw-col-span-2 tw-col-span-1 tw-flex tw-flex-col md:tw-items-start tw-items-center tw-gap-5 md:tw-order-last tw-order-1'>
                    <img src={enigmaFont} className='tw-max-w-[300px]' alt="enigma" />
                    <h1 className='tw-font-goodTime'>MOBILE APPLICATION</h1>
                    <p className='fz-20 tw-font-thin md:tw-text-left tw-text-justify'>Our cutting-edge data management IOS App for secure drives! With our app, you can safeguard your sensitive information with advanced security features. Manage, organize, and access your data effortlessly, all while enjoying the convenience of real-time chat with your network.</p>
                    <div className='md:tw-flex tw-hidden tw-items-center tw-gap-2'>
                        <img src={google} alt="google" />
                        <img src={appStore} alt="apple" />
                    </div>
                </div>
            </div>
        </div>
    )
}
