import { createBrowserRouter } from "react-router-dom";

import App from "./App";

import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutUs/AboutUs";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import CyberSecurityPolicy from "./pages/cyberSecurityPolicy/CyberSecurityPolicy";
import Auth from "./pages/auth/Auth";
import Login from "./pages/auth/login/Login";
import Signup from "./pages/auth/signup/Signup";
import EnigmaKey from "./pages/enigmaKey/EnigmaKey";
import ContactUs from "./pages/contactUs/ContactUs";
import EnigmaSsd from "./pages/enigmaSsd/EnigmaSsd";
import EngimaQuantumSafe from "./pages/enigmaQuantumSafe/EngimaQuantumSafe";
import FrontierLink from "./pages/frontierLink/FrontierLink";
import OurRoadmap from "./pages/ourRoadmap/OurRoadmap";
import Queries from "./pages/queries/Queries";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "",
				element: <Home />,
			},
			{
				path: "aboutUs",
				element: <AboutUs />,
			},
			{
				path: "privacyPolicy",
				element: <PrivacyPolicy />,
			},
			{
				path: "cyberSecurityPolicy",
				element: <CyberSecurityPolicy />,
			},
			{
				path: "auth",
				element: <Auth />,
				children: [
					{
						path: "login",
						element: <Login />,
					},
					{
						path: "signup",
						element: <Signup />,
					},
				],
			},
			{
				path: "enigmaKey",
				element: <EnigmaKey />,
			},
			{
				path: "contactUs",
				element: <ContactUs />,
			},
			{
				path: "enigmaSsd",
				element: <EnigmaSsd />,
			},
			{
				path: "enigmaQuantumSafe",
				element: <EngimaQuantumSafe />,
			},
			{
				path: "frontierLink",
				element: <FrontierLink />,
			},
			{
				path: "our-roadmap",
				element: <OurRoadmap />,
			},
			{
				path: '/queries',
				element: <Queries />,
			}
		],
	},
]);
