import React from 'react';
import { useSelector } from 'react-redux';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';

export default function AuthSwiper(props) {
    // globals
    const { screenWidth } = useSelector(state => state.responsive);

    return (
        <Swiper
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            style={{ height: screenWidth < 768 ? '100vh' : '100vh', position: 'relative' }}
            className='authSwiperPagination'
        >
            {props.data.map(data =>
                <SwiperSlide key={data.id}>
                    <div style={{
                        backgroundImage: `url(${data.bg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: screenWidth < 768 ? 'center' : 'left',
                    }} className='tw-flex tw-flex-col tw-items-center tw-justify-end tw-pb-[6vw] tw-w-full md:tw-h-full tw-h-[100vh]'>
                        <img src={data.icon} alt="icon" className='tw-w-[70px]' />
                        <p className='tw-font-semibold fz-20'>{data.title}</p>
                    </div>
                </SwiperSlide>
            )}
        </Swiper >
    )
}
