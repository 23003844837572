import React from "react";
import { Link, useNavigate } from "react-router-dom";

// assets
import enigmaLogo from "../../assets/icons/logos/enigmaLogo.svg";
import { footerLocations } from "../../data/home/footer";
// import facebook from "../../assets/icons/socials/facebook.svg";
// import twitter from "../../assets/icons/socials/twitter.svg";
// import instagram from "../../assets/icons/socials/instagram.svg";
import linkedin from "../../assets/icons/socials/linkedin.svg";

export default function Footer() {
	// initializations
	const navigate = useNavigate();

	// const insertLineBreaks = (string, maxCharsPerLine) => {
	// 	const result = [];
	// 	let start = 0;
	// 	while (start < string.length) {
	// 		result.push(string.slice(start, start + maxCharsPerLine));
	// 		start += maxCharsPerLine;
	// 	}
	// 	return result.map((line, index) => (
	// 		<React.Fragment key={index}>
	// 			{line}
	// 			<br />
	// 		</React.Fragment>
	// 	));

	// };

	function insertLineBreaks(address) {
		// Split the address by commas and map each part into a span with a line break.
		return address.split(',').map((part, index, array) => (
			// Only add the <br /> if it's not the last part of the address
			<span key={index}>
				{part}{index !== array.length - 1 && <br />}
			</span>
		));
	}


	return (
		<div className="tw-bg-black tw-py-6 content_container">
			<div className=" tw-pt-[3.472vw] tw-pb-[1vw] tw-text-white tw-flex tw-flex-col tw-gap-y-6 tw-justify-center">
				<img
					src={enigmaLogo}
					className="md:tw-w-[10.417vw] tw-w-[40vw] md:tw-h-[2.403vw] tw-h-[8vw] tw-cursor-pointer tw-mx-auto md:tw-block tw-hidden"
					alt="logo"
				/>

				<div className="md:tw-flex tw-hidden tw-items-center tw-gap-10 tw-w-fit tw-mx-auto fz-20">
					<Link to="/aboutUs">About</Link>
					<Link to="/enigmaKey">Enigma Key</Link>
					<Link to="/enigmaSsd">Enigma SSD</Link>
					<Link to="/enigmaQuantumSafe">Enigma Network</Link>
					<Link to="/frontierLink">Enigma Frontier Link</Link>
					<Link to="/contactUs">Contact</Link>
				</div>

				<p className="fz-20 tw-text-[#808080] tw-text-center tw-mt-6">
					Enigma is headquartered in United Kingdom with offices around the world.
				</p>

				<div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 md:tw-mx-auto fz-15 tw-mx-auto tw-w-full tw-gap-y-2">
					{footerLocations.map((location) => (
						<div key={location.id} className="tw-flex tw-items-start tw-gap-x-2 tw-w-fit tw-justify-center md:tw-justify-self-center max-[768px]:tw-ml-[20%]">
							<img src={location.src} alt="location" />
							<div className="tw-flex tw-flex-col tw-items-start">
								<p className="tw-text-[#808080]">
									{location.country}
								</p>
								<p className="">
									{insertLineBreaks(location.address)}
								</p>
								<p className="">
									{location.contact}
								</p>
							</div>
						</div>
					))}
				</div>

				<img
					src={enigmaLogo}
					className="md:tw-w-[10.417vw] tw-w-[40vw] md:tw-h-[2.403vw] tw-h-[8vw] tw-cursor-pointer tw-mx-auto tw-block md:tw-hidden"
					alt="logo"
				/>

				<p className="fz-15 tw-mt-5 tw-text-[#808080] tw-text-center md:tw-block tw-hidden">
					+44 20 4570 1522 | info@enigma.io
				</p>

				<div className="tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-justify-between tw-w-full tw-gap-y-[2vw]">
					<p className=" tw-text-[#808080] tw-text-center">
						© 2023 Enigma All rights reserved
					</p>

					<div className="tw-flex tw-items-center md:tw-gap-[1.389vw] tw-gap-[2.5vw]">
						{/* <img
						src={facebook}
						alt="social"
						className="md:tw-w-[1.389vw] tw-w-[4vw]"
					/> */}
						{/* <img
						src={instagram}
						alt="social"
						className="md:tw-w-[1.389vw] tw-w-[4vw]"
					/> */}
						{/* <img
						src={twitter}
						alt="social"
						className="md:tw-w-[1.389vw] tw-w-[4vw]"
					/> */}
						<img
							src={linkedin}
							alt="social"
							className="md:tw-w-[1.389vw] tw-w-[4vw]"
						/>
					</div>

					<p className="tw-text-[#808080] tw-text-center">
						<span
							className="tw-cursor-pointer"
							onClick={() => navigate("/privacyPolicy")}
						>
							Privacy Policy
						</span>{" "}
						|{" "}
						<span
							className="tw-cursor-pointer"
							onClick={() => navigate("/cyberSecurityPolicy")}
						>
							Cyber Security Policy
						</span>
					</p>
				</div>
			</div>
		</div>
	);
}
