import React from 'react';
import { useSelector } from 'react-redux';

// assets
import light from '../../assets/keyCapabilities/light.svg';
import dark from '../../assets/keyCapabilities/dark.svg';

export default function KeyCapabilities() {
    // globals
    const { inDarkMode } = useSelector(state => state.uiSlice);

    return (
        <div className='tw-pb-[75px]'>
            <div className='tw-w-full content_container'>
                <h1 className='tw-font-goodTime tw-text-themeRed fz-42 tw-text-center'>KEY CAPABILITIES</h1>
                <div className='tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-x-4 tw-items-center tw-mt-5'>
                    <div className='tw-flex tw-flex-col tw-gap-y-4 md:tw-col-span-2 tw-col-span-1 dark:tw-text-white tw-text-black tw-w-full'>
                        <p className='fz-20 tw-font-thin md:tw-text-left tw-text-justify'>
                            <span className='tw-font-bold tw-block'>Unlock Next Generation Security and Privacy Essentials for the Quantum Era!</span>
                            Governments are progressing in the practical application of quantum communications, a highly secure method for transmitting information that is resistant to hacking. Scientists have recently achieved successful demonstrations of quantum communication via satellite, enhancing its utilization in advanced technological applications, particularly in the realms of corporate and military purposes. <br />
                            Discover the Power to: <br /><br />
                            <ul className='tw-list-disc tw-ml-8'>
                                <li>Safeguard classified information by granting access exclusively to individuals with authorized security clearances.</li>
                                <li>Foster collaboration with external partners, ensuring compliance through elevated security controls.</li>
                                <li>Fortify your data's security with robust encryption, whether on-premises, in the cloud, on select hosts, or during secure data transfers between locations.</li>
                                <li>Elevate your organization's security posture and embrace the future of Access Controls!</li>
                            </ul>
                        </p>
                    </div>
                    <img src={inDarkMode ? dark : light} alt="key capabilities" className='tw-col-span-1 tw-bg-radialRedish flex-grow tw-mx-auto' />
                </div>
            </div>
        </div>
    )
}
