import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// assets
import { swiperData } from '../../../data/auth/swiperData';
import enigmaLogo from '../../../assets/icons/logos/enigmaLogo.svg';
import leftCarot from '../../../assets/icons/leftCarot.svg';
import exclamation from '../../../assets/icons/exclamation.svg';

// components
import AuthSwiper from '../../../components/authSwiper/AuthSwiper';
import Modal from '../../../components/wrappers/modal/Modal';

export default function Login() {
    // initializations
    const navigate = useNavigate();

    // locals
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [userData, setUserData] = useState({
        email: '',
        password: ''
    });

    // functions
    const closeOnBackdrop = (e) => {
        if (e.currentTarget === e.target) {
            setShowForgotPasswordModal(false);
        }
    };

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    };

    const submitHandler = () => {
        if (userData.email === '' || userData.password === '') {
            alert('Please fill all the fields');
            return;
        }
        else if (userData.email === 'richard.dsouza@enigmakey.io' && userData.password === '+i#9p7exmVdz') {
            navigate('/queries');
        }
        else {
            alert('Invalid credentials');
        }
    }

    return (
        <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-1 tw-place-items-center tw-text-white tw-max-h-[100vh] tw-relative tw-overflow-y-hidden'>
            <img src={enigmaLogo} alt="logo" className='tw-absolute tw-top-[2%] tw-left-[5%] tw-cursor-pointer' onClick={() => navigate('/')} />

            <div className='md:tw-col-span-4 tw-col-span-1 tw-bg-lightDark md:tw-px-[20vw] tw-px-[9vw] tw-flex tw-flex-col tw-items-center md:tw-gap-y-[1.123vw] tw-gap-y-[2vw] tw-w-full tw-justify-center md:tw-h-full tw-h-[60vh]'>
                <h1 className='tw-font-goodTime'>Sign In</h1>

                <div className="tw-flex tw-flex-col tw-gap-y-2 tw-w-full">
                    <label htmlFor="email" className='tw-text-base'>Email</label>
                    <input type="text" placeholder='Email' className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-slate-300 tw-border-[1px]' name='email' value={userData.email} onChange={handleChange} />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-y-2 tw-w-full">
                    <label htmlFor="password" className='tw-text-base'>Password</label>
                    <input type="password" placeholder='password' className='tw-bg-black tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-slate-300 tw-border-[1px]' name='password' value={userData.password} onChange={handleChange} />
                </div>

                <div className='tw-flex tw-items-center tw-w-full tw-justify-between'>
                    <div className='tw-flex tw-items-center tw-gap-x-[5px]'>
                        <input type="checkbox" className='tw-bg-themeRed' />
                        <p className=''>Remember Me</p>
                    </div>
                    <p className='' onClick={() => setShowForgotPasswordModal(true)}>Forgot Password</p>
                </div>

                <button className='tw-bg-themeRed tw-rounded-lg tw-py-[15px] tw-px-[77px] tw-w-full' onClick={submitHandler}>Next</button>

                <div className='tw-flex tw-items-center tw-justify-center tw-gap-x-[5px] tw-text-sm'>
                    <p>Don't have an account?</p>
                    <Link className='tw-text-blue-500' to={'/auth/signup'}>Signup</Link>
                </div>
            </div>
            <div className='md:tw-col-span-2 tw-col-span-1 tw-w-full md:tw-h-full tw-h-[40vh]'>
                <AuthSwiper data={swiperData} />
            </div>

            {showForgotPasswordModal &&
                <Modal onClose={closeOnBackdrop}>
                    <div className='tw-flex tw-flex-col tw-gap-y-4 tw-items-center tw-text-black tw-w-full tw-px-[2vw] tw-relative'>
                        <img src={exclamation} alt="icon" className='tw-absolute tw-bottom-[98%]' />
                        <h1 className='tw-text-[1.7vw] tw-font-bold tw-text-center tw-w-full tw-mt-[3vw]'>Forgot Password</h1>
                        <h1 className='tw-text-[1.2vw] tw-text-center tw-w-full tw-text-[#505050] '>Enter your email and we'll send you a link to reset your password.</h1>

                        <div className='tw-flex tw-flex-col tw-items-start tw-w-full'>
                            <label htmlFor="email">Email</label>
                            <input type="text" placeholder='Email' className='tw-bg-[#929292] tw-py-[12px] tw-px-[6px] tw-rounded-md tw-text-white tw-w-full tw-border-none tw-outline-none' />
                        </div>

                        <button className='tw-bg-black tw-text-white tw-rounded-lg tw-py-[15px] tw-px-[77px] tw-w-full'>Submit</button>

                        <button className='tw-bg-transparent tw-text-black tw-rounded-lg tw-py-[15px] tw-px-[77px] tw-w-full tw-font-semibold tw-flex tw-items-center tw-gap-x-2' onClick={() => setShowForgotPasswordModal(false)}>
                            <img src={leftCarot} alt="back" className='' />
                            Back to login
                        </button>
                    </div>
                </Modal>
            }
        </div>
    )
}
