import React from 'react';
// import { useSelector } from 'react-redux';
import SimpleButton from '../../components/ui/simpleButton/SimpleButton';

// assets
import { roadMap } from '../../data/home/roadMap';

export default function RoadMap() {
    // globals
    // const { inDarkMode } = useSelector(state => state.uiSlice);

    return (
        <div className='tw-pb-[75px]'>
            <div className='tw-w-full content_container'>
                <h1 className='tw-font-goodTime tw-text-themeRed fz-48 tw-text-center'>ROADMAP</h1>
                <p className='dark:tw-text-white tw-text-black fz-20 md:tw-text-center tw-text-justify tw-font-thin'>
                    Share your most sensitive information securely and confidently with the latest and most advanced security technology. Our military-grade encryption, artificial intelligence, and private blockchain technology ensure your data is <span className='tw-text-themeRed'>safe and secure.</span>
                </p>
                <SimpleButton navigate='/our-roadmap' text={`Explore Now`} className='tw-text-white tw-w-fit tw-mx-auto tw-mt-6' />

                {/* <div className='tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4 tw-mt-[4vw]'>
                {roadMap.map((phase, index) =>
                    <div className={`tw-grid tw-grid-cols-3 tw-w-full tw-gap-x-4 tw-items-center'}`}>
                        <img src={phase.lightSrc} className='tw-col-span-1 tw-bg-radialRed' alt="phase" />
                        <div className='tw-col-span-2 tw-flex tw-flex-col tw-items-start tw-gap-y-2'>
                            <h1 className='dark:tw-text-white tw-text-black md:tw-text-[2vw] tw-text-[6vw] tw-font-semibold tw-text-center'>{phase.title}</h1>
                            <ul className='tw-list-disc tw-gap-y-1 tw-flex tw-flex-col'>
                                {phase.data.map((data, index) => <li key={index} className='dark:tw-text-white tw-text-black md:tw-text-[1.2vw] tw-text-[3vw]'>{data}</li>)}
                            </ul>
                        </div>
                    </div>
                )}
            </div> */}
            </div>
        </div>
    )
}
