import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// redux
// import { toggleDarkMode } from '../../store/uiSlice';

// assets
import usa from '../../assets/icons/flags/usa.svg';
import uk from '../../assets/icons/flags/uk.svg';
import canada from '../../assets/icons/flags/canada.svg';
// import day from '../../assets/icons/theme/day.svg';
// import night from '../../assets/icons/theme/night.svg';

export default function ContactNav() {
    // initializaitons
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // globals
    // const { inDarkMode } = useSelector(state => state.uiSlice);

    return (
        <div className='tw-bg-themeRed tw-sticky tw-top-0 tw-z-[4] md:tw-flex tw-hidden tw-items-center tw-h-[30px] fz-20'>
            <div className='content_container tw-w-full tw-flex tw-items-center tw-justify-between tw-text-white '>
                <div className='tw-flex tw-items-center tw-gap-x-4'>
                    <div className='tw-flex tw-items-center tw-gap-x-2'>
                        <img src={usa} className='tw-max-w-[20px]' alt="usa" />
                        <p className=''>+1 929 777 3677</p>
                    </div>
                    <div className='tw-flex tw-items-center tw-gap-x-2'>
                        <img src={uk} className='tw-max-w-[20px]' alt="uk" />
                        <p className=''>+44 20 4570 1522</p>
                    </div>
                    <div className='tw-flex tw-items-center tw-gap-x-2'>
                        <img src={canada} className='tw-max-w-[20px]' alt="canada" />
                        <p className=''>+1 289 904 5262</p>
                    </div>
                </div>
                <div className='tw-flex tw-items-center tw-gap-x-4'>
                    <p className={`${location.pathname === '/queries' && 'tw-hidden'}`}><span className='tw-cursor-pointer' onClick={() => navigate('/auth/signup')}>Sign Up</span> / <span className='tw-cursor-pointer' onClick={() => navigate('/auth/login')}>Sign In</span></p>
                    {/* <div className='tw-flex tw-items-center tw-rounded-full tw-bg-black tw-px-[0.347vw] tw-cursor-pointer tw-py-[0.313vw] tw-gap-x-[0.903vw]' onClick={() => dispatch(toggleDarkMode())}>
                    <img className={`tw-rounded-full tw-p-[0.2vw] tw-w-[0.694vw] tw-h-[0.694vw] tw-box-content ${!inDarkMode ? 'tw-bg-themeRed tw-text-white' : ''}`} src={day} alt="day" />
                    <img className={`tw-rounded-full tw-p-[0.2vw] tw-w-[0.694vw] tw-h-[0.694vw] tw-box-content ${inDarkMode ? 'tw-bg-themeRed' : ''}`} src={night} alt="night" />
                </div> */}
                </div>
            </div>
        </div>
    )
}
