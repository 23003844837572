import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useNavigate, ScrollRestoration, useLocation } from 'react-router-dom';

// redux
import { responsiveActions } from './store/responsiveSlice';

// components
import ContactNav from './components/navbar/ContactNav';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

function App() {
	// initializations
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	// globals
	const { inDarkMode } = useSelector(state => state.uiSlice);

	// effects
	useEffect(() => {
		if (location.pathname === "/") {
			// navigate('/home')
		}
	}, [navigate, location])

	useEffect(() => {
		if (inDarkMode) {
			document.documentElement.classList.add('tw-dark');
		} else {
			document.documentElement.classList.remove('tw-dark');
		}
	}, [inDarkMode])

	useEffect(() => {
		// Add the event listener on component mount
		window.addEventListener('resize', handleWindowResize);

		// Clean up the event listener on component unmount
		// return () => {
		//     window.removeEventListener('resize', handleWindowResize);
		// };
	}, []);

	// Determine if the current route is an auth route
	const isAuthRoute = location.pathname.includes('/auth');

	// funcvtions
	const handleWindowResize = () => {
		dispatch(responsiveActions.setScreenWidth({ screenWidth: window.innerWidth }));
	};

	return (
		<div className={` tw-relative tw-overflow-x-hidden`}>
			<ScrollRestoration />
			<div className='tw-fixed tw-top-0 tw-left-0 tw-w-full tw-z-50'>
				{!isAuthRoute && <ContactNav />}
				{!isAuthRoute && <Navbar />}
			</div>
			<div className='tw-bg-lightDark'>
				<Outlet />
			</div>
			{!isAuthRoute && <Footer />}
		</div>
	);
}

export default App;
