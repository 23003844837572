import usa from '../../assets/icons/location/usa.svg';
import uk from '../../assets/icons/location/uk.svg';
import canada from '../../assets/icons/location/canada.svg';

export const footerLocations = [
    {
        id: 1,
        src: usa,
        country: "United States",
        address: "1810 E Sahara Ave, Las Vegas NV 89104",
        contact: "+1 929 777 3677"
    },
    {
        id: 2,
        src: uk,
        country: "United Kingdom",
        address: "7 Bell Yard, London WC2A 2JR",
        contact: "+44 20 4570 1522"
    },
    {
        id: 3,
        src: canada,
        country: "Canada",
        address: "170-422 Richards Street, Vancouver BC V6B 2Z4",
        contact: "+1 289 904 5262"
    }
]